export const tokenUserPermissions = {
  CUSTOMER_PORTAL: "customerPortal",
  //REPRESENTATIVE_TYPES: { CLIENT: "Client", BROKER: "Broker" },
  //MANUFACTURING_DATA: { GET: "ms:get" },
  //ORDER_MANAGEMENT_DATA: { ALL: "om:all" },
  //BOX_INSIGHTS: { GET: "bxi:get" },
  //VIEW_EMBEDDED_REPORTS: { GET: "cEmbeddedReports:get" },
};

export const tokenPermissions = {
  USER: "user",
  EMAIL_UNSUBSCRIBE: "email:unsubscribe"
}
