import React, { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Grid, Segment, Icon } from "semantic-ui-react";
import { CustomerGroupSelector } from "../../common/CustomerGroupSelector/CustomerGroupSelector";
import { CustomerSelectionSlider } from "../../common-mobile/CustomerSelectionSlider/CustomerSelectionSlider";
import { SelectedCustomerGroupContext } from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import PastBookings from "./PastBookings";
import { Link } from "react-router-dom";

export const UpcomingAndPastBookings = () => {
  const [selectedITOYear, setSelectedITOYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);

  useEffect(() => {
    if (yearOptions) {
      const currentYear = new Date().getFullYear();
      setSelectedITOYear(currentYear.toString());
    }
  }, [yearOptions]);

  return (
    <>
      <Helmet>
        <title>De Beers Group Sight Room Reservation Portal - Reservations</title>
      </Helmet>
      <Grid>
        <Grid.Row className="customer-selection-header desktop">
          <Grid.Column computer={4} mobile={16}>
            <CustomerGroupSelector hideCustomerSelector={true} />
          </Grid.Column>
          <Grid.Column
            computer={4}
            mobile={16}
            className="current-sight-column"
          ></Grid.Column>
          <Grid.Column computer={16} mobile={16}>
            <div className="border"></div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="mobile-view">
          <Grid.Column width={16}>
            <CustomerSelectionSlider
              setSelectedYear={setSelectedITOYear}
              selectedYear={selectedITOYear}
              yearOptions={yearOptions}
              displayCustomer={false}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Link to="/" className="back-navigation-button-text-color">
              <Icon name="left arrow" /> Back
            </Link>
            <Segment>
              <PastBookings
                selectedItoYear={selectedITOYear}
                selectedCustomerGroup={selectedCustomerGroup}
                isPast={false}
              ></PastBookings>
              <br></br>
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <br></br>
            <Segment>
              <PastBookings
                selectedItoYear={selectedITOYear}
                selectedCustomerGroup={selectedCustomerGroup}
                isPast={true}
              ></PastBookings>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default UpcomingAndPastBookings;
