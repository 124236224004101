import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { LoaderComponent } from "../../common/LoaderComponent";
import OktaLogin from "./OktaLogin"

const SignIn = () => {
  const { authState } = useOktaAuth();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  if (authState && authState.isPending) {
    return (
      <div>
        <LoaderComponent loading={true} message={"Busy logging in..."} />
      </div>
    );
  }

  return authState && authState.isAuthenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <OktaLogin/>
  );
};

export default SignIn;
