import "./NavBarIcons.scss";
import { Icon } from "semantic-ui-react";
import React from "react";
import { useHistory } from "react-router-dom";


export const NavBarIconsContainer = () => {
    const history = useHistory();

    const handleHomeClick = () => {
        history.push({
          pathname: `/`,
        });
       }

    return (
        <>
        <Icon
          name="home"
          size="large"
          className="home"
          onClick={handleHomeClick}
        >
        </Icon>
        </>
        
    );
};