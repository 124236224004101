import "./BookingItems.scss";
import React from "react";
import { Grid, Card, Button } from "semantic-ui-react";
import { UilTrashAlt, UilCalender, UilEditAlt } from "@iconscout/react-unicons";

export const BookingItems = ({
  bookingItems,
  removeBookingItem,
  openBookingItem,
  selectedItem,
  isEditButtonVisible,
  duplicateBookingItems,
}) => {
  return (
    <>
      {bookingItems?.length > 0 &&
        bookingItems?.map((bookingItem, index) => {
          const isDuplicate = duplicateBookingItems?.some((duplicate) => 
            duplicate.day === bookingItem.day &&
            duplicate.month === bookingItem.month &&
            duplicate.timeSlot === bookingItem.timeSlotString &&
            duplicate.roomName === bookingItem.roomName
      );

          return (
            <Card
              className={`notification-card ${
                bookingItem?.index === selectedItem?.index
                  ? "booking-item-card isSelected"
                  : "booking-item-card isNotSelected"
              } ${isDuplicate ? "duplicate-booking-item" : ""}`}
              key={index}
            >
              <Grid>
                <Grid.Row>
                  <Grid.Column computer={6} mobile={16} tablet={16}>
                    <div className="booking-item-date">
                      <UilCalender className="icon-booking-item" />
                      <h1 className="booking-item-h1-heading">
                        {bookingItem.dateString}
                      </h1>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column computer={4} mobile={8} tablet={4}>
                    <p className="booking-item-spacing">Room</p>
                    <h2 className="booking-item-h2-heading">
                      {bookingItem.roomName}
                    </h2>
                  </Grid.Column>
                  <Grid.Column computer={8} mobile={8} tablet={8}>
                    <p className="booking-item-spacing">Time slot</p>
                    <h2 className="booking-item-h2-heading">
                      {bookingItem.timeSlotString}
                    </h2>
                  </Grid.Column>
                  <Grid.Column
                    computer={4}
                    mobile={10}
                    tablet={4}
                    className="right-text"
                  >
                    {isEditButtonVisible && (
                      <Button
                        onClick={() => openBookingItem(bookingItem)}
                        icon={UilEditAlt}
                        className={`ui icon button ${
                          bookingItem?.index === selectedItem?.index
                            ? "booking-item-edit-icon isSelected"
                            : "booking-item-edit-icon isNotSelected"
                        }`}
                      >
                        <UilEditAlt className="icon-booking-item" />
                      </Button>
                    )}
                      <Button
                        onClick={() => removeBookingItem(bookingItem)}
                        icon={UilTrashAlt}
                        className="ui icon button booking-item-trash-icon"
                      >
                        <UilTrashAlt className="icon-booking-item" />
                      </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card>
          );
        })}
    </>
  );
};

export default BookingItems;
