import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { LoginContext } from "./LoginContext";
import {
  clearAuthStorage,
  handleOktaToken,
  setUserNameCache,
  isLoggedIn,
} from "../../lib/authentication";
import { LoaderComponent } from "../../common/LoaderComponent";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";

const showLoader = (authState, loginState) => {
  return (
    authState &&
    (authState.isPending ||
      loginState.isPending ||
      (authState.isAuthenticated && !loginState.isLoggedIn)
    ));
};

const checkIfShouldTriggerOktaLogout = async (
  authState,
  timer,
  cycles,
  setCycles,
  oktaAuth
) => {
  if (authState.isPending && cycles > 2) {
    clearInterval(timer);
    await oktaAuth.signOut();
  } else {
    setCycles((prevState) => prevState + 1);
  }
};

const oktaSetLoggedInAsync = async (
  accessToken,
  setLoginState,
  setLoginException
) => {
  setLoginState({ isLoggedIn: false, isPending: true });
  try {
    setLoginException(false);
    await handleOktaToken(accessToken.accessToken);
    if (isLoggedIn()) {
      await setUserNameCache();
      setLoginState({ isLoggedIn: true, isPending: false });
    }
  } catch {
    setLoginState({ isLoggedIn: false, isPending: false });
    setLoginException(true);
  }
};

const LoginWrapper = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [loginException, setLoginException] = useState(false);
  const [cycles, setCycles] = useState(0);
  const [loginState, setLoginState] = useState({
    isLoggedIn: isLoggedIn(),
    isPending: !isLoggedIn() && authState && authState.isAuthenticated,
  });
  const isLoggedInValue = {
    loginState: loginState,
    setLoginState: setLoginState,
  };
  const location = useLocation();

  useEffect(() => {
    if(authState) {
      if (!authState || (!authState.isAuthenticated && !authState.isPending)) {
        setLoginState({ isLoggedIn: false, isPending: false });
        clearAuthStorage();
      } else if (
        !loginState.isLoggedIn &&
        authState.isAuthenticated &&
        !authState.isPending
      ) {
        oktaSetLoggedInAsync(
          authState.accessToken,
          setLoginState,
          setLoginException
        );
      }
      return () => {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  useEffect(() => {
    if (showLoader(authState, loginState)) {
      const timer = setInterval(() => {
        checkIfShouldTriggerOktaLogout(
          authState,
          timer,
          cycles,
          setCycles,
          oktaAuth
        );
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [authState, loginState, cycles, oktaAuth]);

  if (loginException) {
    return <Redirect to="/logout" />;
  }

  if (location.pathname === "/logout") {
    return null;
  }

  if (showLoader(authState, loginState)) {
    return (
      <div>
        <LoaderComponent loading={true} message={"Busy logging in..."} />
      </div>
    );
  }

  return (
    <LoginContext.Provider value={isLoggedInValue}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginWrapper;
