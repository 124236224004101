import "./CommonStyles.scss";
import "./RoomBookingsManageContainer.scss";
import React, { useContext, useEffect, useState } from "react";
import { CustomerSelectionSlider } from "../../common-mobile/CustomerSelectionSlider/CustomerSelectionSlider";
import { SelectedCustomerGroupContext } from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import {
  Popup as SemanticPopup,
  Grid,
  Segment,
  Button,
  Form,
  Icon,
} from "semantic-ui-react";
import { getCustomerGroup } from "../../lib/apiCustomerGroups";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";
import * as Unicons from "@iconscout/react-unicons";
import {
  ConfirmationModal,
  confirmationChoices,
} from "../../common/ConfirmationModal/ConfirmationModal";
import Helmet from "react-helmet";
import {
  updateBooking,
  deleteBooking,
  getBookingAsync,
} from "../../lib/apiBookings";
import { useHistory, Prompt, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { Popup } from "../../common/Popup/Popup";
import { notifySuccess } from "../../lib/notifications";
import { isNil } from "lodash";

/*
TODO: Clean up file:
-add more validations on the attendees and test more
- remove all in-line styling
- optimise some functions
*/

export const fetchCustomerGroup = async (setCustomerGroup, customerGroupId, history, setSelectedCustomerGroup) => {
  if (customerGroupId) {
    const customerGroup = await getCustomerGroup(customerGroupId);

    if(!customerGroup)
    {
      setSelectedCustomerGroup(null);

      console.log("Could not find customer group with id: ", customerGroupId);
      
      history.push({
        pathname: '/not-authorized',
        state: {},
      });

      return;
    }

    setCustomerGroup(customerGroup);

    return customerGroup;
  }
};

const getBooking = async (
  setBooking,
  bookingId,
  setLoading,
  setBookingWindow
) => {
  try {
    setLoading(true);
    const bookingResponse = await getBookingAsync(bookingId);
    if (bookingResponse) {
      setBooking(bookingResponse);
      setBookingWindow(bookingResponse.bookingWindow);
    }
  } finally {
    setLoading(false);
  }
};

export const RoomBookingsManageContainer = ({
  match: {
    params: { bookingId },
  },
}) => {
  const hoursBeforeBooking = parseInt(
    process.env.REACT_APP_NO_OF_HOURS_ALLOWED_BEFORE_BOOKING_START,
    10
  );

  const [selectedITOYear, setSelectedITOYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customerGroup, setCustomerGroup] = useState();
  const [specialRequests, setSpecialRequests] = useState("");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [bookingWindow, setBookingWindow] = useState();
  const [booking, setBooking] = useState();
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { selectedCustomerGroup, setSelectedCustomerGroup } = useContext(SelectedCustomerGroupContext);
  const { isReadOnly } = location.state || {};

  const [attendees, setAttendees] = useState([{ firstName: "", lastName: "" }]);
  const [errors, setErrors] = useState([{ firstName: false, lastName: false }]);

  const [isRebookDisabled, setIsRebookDisabled] = useState(false);

  useEffect(() => {
    if(booking)
    {
      if (selectedCustomerGroup) {
        fetchCustomerGroup(setCustomerGroup, selectedCustomerGroup, history, setSelectedCustomerGroup);
      } else {
        setSelectedCustomerGroup(booking.customerGroupId);
      }
    }
  }, [selectedCustomerGroup, setCustomerGroup, booking]);

  useEffect(() => {
    if (yearOptions) {
      const currentYear = new Date().getFullYear();
      setSelectedITOYear(currentYear.toString());
    }
  }, [yearOptions]);

  useEffect(() => {
    getBooking(setBooking, bookingId, setLoading, setBookingWindow);
  }, [bookingId, setBooking, setBookingWindow]);

  useEffect(() => {
    if (booking) {
      if (booking.specialRequests) setSpecialRequests(booking.specialRequests);

      if (booking.bookingAttendees) {
        const newList = booking?.bookingAttendees.map((ba) => {
          return {
            firstName: ba.attendee?.firstName,
            lastName: ba.attendee?.lastName,
            attendeeId: ba.attendeeId,
          };
        });

        setAttendees(newList);
      }
    }
  }, [booking]);

  useEffect(() => {
    //TODO: Is this correct with index 0?
    const startTime = new Date(booking?.bookingItems[0].startTime);

    const currentTime = new Date();
    const allowedTime = new Date(startTime);
    allowedTime.setHours(startTime.getHours() - hoursBeforeBooking);

    if (currentTime > allowedTime) {
      setIsRebookDisabled(true);
    }
  }, [booking]);

  const validateAllAttendees = (attendees) => {
    const errors = attendees.map((attendee) => ({
      firstName: isNil(attendee.firstName) || attendee.firstName.trim() === "",
      lastName: isNil(attendee.lastName) || attendee.lastName.trim() === "",
    }));
  
    const hasNoErrors = errors.every(
      (error) => !error.firstName && !error.lastName
    );
  
    setErrors(errors);
    setIsSaveEnabled(hasNoErrors);
  };

  const isEmpty = attendees.every(
    (attendee) =>
      attendee.firstName.trim() === "" && attendee.lastName.trim() === ""
  );

  const handleAttendeeChange = (index, field, value) => {
    const updatedAttendees = [...attendees];
    updatedAttendees[index][field] = value;
    setAttendees(updatedAttendees);
  
    validateAllAttendees(updatedAttendees);
  };

  const addAttendee = () => {
    const updatedAttendees = [...attendees, { firstName: "", lastName: "" }];
    setAttendees(updatedAttendees);
  
    validateAllAttendees(updatedAttendees);
  };  

  const removeAttendee = (index) => {
    const updatedAttendees = attendees.filter((_, i) => i !== index);
    setAttendees(updatedAttendees);

    validateAllAttendees(updatedAttendees);
  };
  

  const saveSpecialRequests = (e, { value }) => {
    setSpecialRequests(value);

    const errors = attendees.map((attendee) => ({
      firstName: attendee.firstName.trim() === "",
      lastName: attendee.lastName.trim() === "",
    }));

    const hasNoErrors = errors.every(
      (error) => !error.firstName && !error.lastName
    );

    setErrors(errors);
    setIsSaveEnabled(attendees.length > 0 && hasNoErrors);
  };

  const updateBookingDetails = async () => {
    setSaveLoading(true);

    try {
      const bookingAttendees = attendees.map((a) => {
        return {
          attendeeId: a.attendeeId,
          attendee: {
            attendeeId: a.attendeeId,
            firstName: a.firstName,
            lastName: a.lastName,
          },
        };
      });

      booking.specialRequests = specialRequests;
      booking.bookingAttendees = bookingAttendees;

      await updateBooking(booking);
      setSaveLoading(false);
      setIsSaveEnabled(false);
    } catch (err) {}
  };

  const cancelBooking = async () => {
    setSaveLoading(true);

    try {
      await deleteBooking(booking?.bookingId);
      setSaveLoading(false);

      history.push({
        pathname: "/reservations",
        state: {
          from: "/reservations/manage-reservation/:bookingId",
          booking: booking,
          customerGroupSelected: customerGroup?.name,
          itoYearSelected: selectedITOYear,
        },
      });
    } catch (err) {}
  };

  const closeConfirmationModal = (confirmationChoice) => async () => {
    setConfirmationLoading(true);
    if (confirmationChoices.confirm === confirmationChoice) {
      try {
        cancelBooking(booking);
        notifySuccess("Reservation cancelled successfully.");
      } catch {
        return;
      }
    }
    setDeleteConfirmationModalOpen(false);
    setConfirmationLoading(false);
  };

  //TODO: CHANGE ENDPOINT TO USE DISPLAY DTO
  const getDayOfTheWeek = (bookingItem) => {
    return bookingItem?.startTime
      ? moment(bookingItem.startTime).format("ddd")
      : "";
  };

  const getDay = (bookingItem) => {
    return bookingItem?.startTime
      ? String(new Date(bookingItem.startTime).getDate()).padStart(2, "0")
      : "";
  };

  const getMonth = (bookingItem) => {
    return bookingItem?.startTime
      ? moment(bookingItem.startTime).format("MMM")
      : "";
  };

  const getTimeSlot = (bookingItem) => {
    if (!bookingItem?.endTime || !bookingItem?.startTime) return "";

    const endTime = new Date(bookingItem.endTime);
    const startTime = new Date(bookingItem.startTime);

    // Extract hours from the time
    const endHours = endTime.getHours();
    const startHours = startTime.getHours();

    if (endHours === 11) {
      return "Morning (7am - 1pm)";
    } else if (startHours >= 11) {
      return "Afternoon (1:30pm - 7pm)";
    } else {
      return "Entire Day (7am - 7pm)";
    }
  };

  return (
    <>
      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        closeModal={closeConfirmationModal}
        confirmLoading={confirmationLoading}
        heading="Cancel Reservation"
        content={
          <div className="div-booking-item">
            <Unicons.UilExclamationCircle color={"#C62931"} size={25} /> You are
            about to cancel and delete this reservation. Please confirm that you
            would like to perform this action.
          </div>
        }
      />
      <Helmet>
        <title>De Beers Group Sight Room Reservation Portal - Reservations</title>
      </Helmet>
      <Grid>
        <Grid.Row className="customer-selection-header">
          <Grid.Column
            computer={6}
            mobile={16}
            tablet={16}
            className="customer-group-heading"
          >
            Sightholder: {customerGroup?.name}
          </Grid.Column>
          <Grid.Column computer={16} mobile={16}>
            <div className="border"></div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="row-back-room-bookings">
          <Link to="/" className="back-navigation-button-text-color">
            <Icon name="left arrow" /> Back
          </Link>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={6} tablet={6} computer={8}>
            <h1 className="manage-booking-h1-heading">
              {booking?.bookingWindow?.name}
            </h1>
          </Grid.Column>
          {!isReadOnly && (
            <Grid.Column mobile={10} tablet={10} computer={8}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <SemanticPopup
                  trigger={
                    <div>
                      <Button
                        primary
                        as={Link}
                        to={`/reservations/edit/${booking?.bookingId}`}
                        disabled={isRebookDisabled}
                        className="btn-primary"
                      >
                        Update Reservation
                      </Button>
                    </div>
                  }
                  position="top center"
                  content={`Cannot edit reservation within ${hoursBeforeBooking} hours of the start time.`}
                  disabled={!isRebookDisabled}
                />

                <SemanticPopup
                  trigger={
                    <div>
                      <Button
                        secondary
                        onClick={() => setDeleteConfirmationModalOpen(true)}
                        className="btn-secondary"
                        disabled={isRebookDisabled}
                      >
                        Cancel Reservation
                      </Button>
                    </div>
                  }
                  position="top center"
                  content={`Cannot edit reservation within ${hoursBeforeBooking} hours of the start time.`}
                  disabled={!isRebookDisabled}
                />
              </div>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <h1 className="manage-booking-h1-heading-summary">Summary</h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="row-padding-top">
          <Grid.Column mobile={5} tablet={4} computer={2}>
            <p>Location</p>
            <p className="standard-booking-item-details">
              {booking?.bookingWindow?.location?.name}
            </p>
          </Grid.Column>
          <Grid.Column mobile={4} tablet={4} computer={2}>
            <p>Attendees</p>
            <p className="standard-booking-item-details">
              {booking?.attendeesCount}
            </p>
          </Grid.Column>
          <Grid.Column mobile={6} tablet={4} computer={2}>
            <p>Total Days</p>
            <p className="standard-booking-item-details">
              {booking?.bookingItems?.length}
            </p>
          </Grid.Column>
          {/* TODO: Is this needed? Please see BookingDisplayDto comment */}
          {/* <Grid.Column mobile={4} tablet={4} computer={2}>
            <p>Booked by</p>
            <p className="standard-booking-item-details">
              {booking?.createdBy}
            </p>
          </Grid.Column> */}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <StandardSmallHeading>Reservation dates</StandardSmallHeading>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Grid>
              {/* TODO: MOVE THIS TO A COMMON PLACE TO BE SHARED WITH CURRENT RESERVATIONS */}
              <Grid.Row>
                {booking?.bookingItems?.length > 0 &&
                  booking?.bookingItems.map((bookingItem, index) => (
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                      <Segment className="segment-booking-card-details">
                        <Grid columns={2}>
                          <Grid.Column mobile={2} tablet={4} computer={2}>
                            <div>
                              <p className="p-booking-item-week-day">
                                {getDayOfTheWeek(bookingItem)}
                              </p>
                              <h2 className="h2-booking-item-day">
                                {getDay(bookingItem)}
                              </h2>
                              <p className="p-booking-item-week-day">
                                {getMonth(bookingItem)}
                              </p>
                            </div>
                          </Grid.Column>
                          <Grid.Column mobile={14} tablet={16} computer={8}>
                            <div className="div-booking-item">
                              {" "}
                              <Unicons.UilLocationPoint className="icon-booking-item" />
                              <p>
                                {booking.bookingWindow?.location?.name},{" "}
                                {bookingItem.room?.name}
                              </p>
                            </div>
                            <br></br>
                            <div className="div-booking-item">
                              <Unicons.UilClockThree className="icon-booking-item" />
                              <p>{getTimeSlot(bookingItem)}</p>
                            </div>
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    </Grid.Column>
                  ))}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={6} tablet={16} computer={8}>
            <StandardSmallHeading>Additional information</StandardSmallHeading>
          </Grid.Column>
          {(!isReadOnly && isRebookDisabled) ||
            (!isRebookDisabled && (
              <Grid.Column mobile={10} tablet={16} computer={8}>
                <Button
                  primary
                  floated="right"
                  onClick={updateBookingDetails}
                  loading={saveLoading}
                  disabled={isEmpty || !isSaveEnabled}
                  className="btn-primary"
                >
                  Save Changes
                </Button>
              </Grid.Column>
            ))}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Segment className="special-requests-text-area">
              <StandardSmallHeading>Special Requests</StandardSmallHeading>
              <br />
              {!isReadOnly && (
                <>
                  <p>
                    Kindly let us know if there is any additional information you can share with us to ensure you have the best experience during your time with us in Gaborone.
                  </p>
                  <br />
                </>
              )}
              {!specialRequests && isReadOnly ? (
                <p className="p-text-colour">No Special Requests added.</p>
              ) : (
                <Form>
                  <Form.Field>
                    <Form.Group width="equal" fluid>
                      <Form.TextArea
                        className="input-textarea-border"
                        id="specialRequests"
                        name="specialRequests"
                        placeholder={
                          isReadOnly
                            ? "No Special Requests added."
                            : "Enter your request"
                        }
                        value={specialRequests}
                        onChange={saveSpecialRequests}
                        maxLength={500}
                        rows="4"
                        width={16}
                        readOnly={isReadOnly}
                      />
                    </Form.Group>
                  </Form.Field>
                </Form>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    <div className="div-booking-item">
                      <h1 className="manage-booking-h1-heading-attendee">
                        Attendee names
                        {!isReadOnly && (
                          <span className="manage-booking-asterisk">*</span>
                        )}
                      </h1>

                      <br />
                      {isEmpty && !isRebookDisabled && (
                        <Popup
                          content={"Please add attendee names to this reservation no later than 1 week before Sight, otherwise the reservation will be cancelled."}
                          Icon={Unicons.UilExclamationCircle}
                          iconClassNames="error-info-icon"
                        />
                      )}
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={8}
                  ></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    {!isReadOnly && (
                      <p className="p-text-colour">
                        Add the full names of all the attendees.
                      </p>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="row-padding-bottom">
                  <Grid.Column mobile={24} tablet={16} computer={16}>
                    {!attendees && isReadOnly ? (
                      <p className="p-text-colour">No Attendee names added.</p>
                    ) : (
                      <Form>
                        {attendees.map((name, index) => (
                          <Grid>
                            <Grid.Row className="row-padding-bottom">
                              <Grid.Column  mobile={12} tablet={16} computer={7}>
                                <Form.Input
                                  className="input-field-border attendee-text-area "
                                  placeholder={`First Name`}
                                  value={name.firstName}
                                  onChange={(e) =>
                                    handleAttendeeChange(
                                      index,
                                      "firstName",
                                      e.target.value
                                    )
                                  }
                                  required
                                  readOnly={isReadOnly}
                                  error={
                                    errors[index]?.firstName
                                  }
                                />
                              </Grid.Column>
                              <Grid.Column mobile={12} tablet={16} computer={7}>
                                <Form.Input
                                  className="input-field-border attendee-text-area "
                                  placeholder={`Last Name`}
                                  value={name.lastName}
                                  onChange={(e) =>
                                    handleAttendeeChange(
                                      index,
                                      "lastName",
                                      e.target.value
                                    )
                                  }
                                  required
                                  readOnly={isReadOnly}
                                  error={
                                    errors[index]?.lastName 
                                  }
                                />
                              </Grid.Column>

                              <Grid.Column mobile={4} tablet={16} computer={2}>
                                <Button
                                  icon
                                  onClick={() => removeAttendee(index)}
                                  type="button"
                                  className="attendee-icon-button"
                                >
                                  <Icon name="minus" />
                                </Button>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        ))}
                      </Form>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="row-padding-top">
                  {!isReadOnly && (
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <br/>
                      <Button
                        fluid
                        secondary
                        onClick={addAttendee}
                        className="btn-secondary"
                      >
                        Add Name
                      </Button>
                    </Grid.Column>
                  )}
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default RoomBookingsManageContainer;
