import React, { useState, useEffect, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { orchestrateLogoutAsync } from "./Logout.functions";
import { Redirect } from "react-router-dom";
import { LoginContext } from "../../modules/login/LoginContext";
import { LoaderComponent } from "../../common/LoaderComponent";

export const Logout = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [loggedOut, setLoggedOut] = useState(false);
  const { setLoginState } = useContext(LoginContext);

  useEffect(() => {
    orchestrateLogoutAsync(oktaAuth, setLoggedOut, setLoginState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((authState && (authState.isAuthenticated || authState.isPending)) || !loggedOut) {
    return (
      <div>
        <LoaderComponent loading={true} message={"Busy logging out..."} />
      </div>
    );
  }

  return <Redirect to="/" />;
};

export default Logout;
