export function GetOktaConfig() {
  const oktaConfigValue = process.env.REACT_APP_OKTA_CONFIG;
  const oktaConfig = JSON.parse(oktaConfigValue);

  oktaConfig.tokenManager = {
    expireEarlySeconds: 300, // 5 minutes early
    autoRenew: true,
  };

  return oktaConfig;
}
