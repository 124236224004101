import "./CommonStyles.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import {
  Popup as SematicPopup,
  Grid,
  Segment,
  Button,
  Form,
  Radio,
  Message,
  Label,
  Modal,
  Header,
} from "semantic-ui-react";
import * as Unicons from "@iconscout/react-unicons";
import { CustomerSelectionSlider } from "../../common-mobile/CustomerSelectionSlider/CustomerSelectionSlider";
import { SelectedCustomerGroupContext } from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import BookingDayCard from "./Cards/BookingDayCard";
import BookingTimeSlotCard from "./Cards/BookingTimeSlotCard";
import { getLocationsAsync, getLocationAsync } from "../../lib/apiLocations";
import { getBookingWindowDatesAsync } from "../../lib/apiBookingWindows";
import { getRoomsMapUrl } from "../../lib/apiRooms";
import {
  getBookingAsync,
  updateBooking,
  getAvailableRoomsAsync,
} from "../../lib/apiBookings";
import BookingItems from "./BookingItems";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { getCustomerGroup } from "../../lib/apiCustomerGroups";
import { NumericFormat } from "react-number-format";
import { Popup } from "../../common/Popup/Popup";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";
import {
  ConfirmationModal,
  confirmationChoices,
} from "../../common/ConfirmationModal/ConfirmationModal";
import { LoaderComponent } from "../../common/LoaderComponent";
 
/*
TODO: Clean up file:
- ensure you cannot book a date in the past AND CERTAIN HOURS BEFORE RESERVATION START
- add signal r and cater for room availability
- move shared methods to a file with Create container
- remove all in-line styling
- optimise some functions
*/
 
const getLocations = async (setLocations, locations, setLoading) => {
  try {
    if (locations == null || locations?.length === 0) {
      setLoading(true);
      const locationResponse = await getLocationsAsync();
      if (locationResponse && locationResponse.length > 0) {
        setLocations(locationResponse);
      }
    }
  } finally {
    setLoading(false);
  }
};
 
const getLocation = async (setLocation, location, setLoading) => {
  try {
    if (location) {
      setLoading(true);
      const locationResponse = await getLocationAsync(location);
      if (locationResponse) {
        setLocation(locationResponse);
      }
    }
  } finally {
    setLoading(false);
  }
};
 
const getBooking = async (
  setBooking,
  bookingId,
  setLoading,
  setBookingWindow
) => {
  try {
    setLoading(true);
    const bookingResponse = await getBookingAsync(bookingId);
    if (bookingResponse) {
      setBooking(bookingResponse);
      setBookingWindow(bookingResponse.bookingWindow);
    }
  } finally {
    setLoading(false);
  }
};
 
const getBookingWindowDates = async (
  setLoading,
  setBookingOpeningDates,
  bookingWindowId,
  customerGroupId
) => {
  setLoading(true);
  if (bookingWindowId) {
    const data = await getBookingWindowDatesAsync(bookingWindowId, customerGroupId);
 
    await setBookingOpeningDates(data);
  }
 
  setLoading(false);
};
 
const getAvailableRooms = async (
  setAvailableRooms,
  bookingWindowId,
  startDate,
  endDate
) => {
  if (bookingWindowId) {
    const data = await getAvailableRoomsAsync(
      bookingWindowId,
      startDate,
      endDate
    );
 
    await setAvailableRooms(data);
  }
};
 
export const fetchCustomerGroup = async (setCustomerGroup, customerGroupId) => {
  if (customerGroupId) {
    const customerGroup = await getCustomerGroup(customerGroupId);
    setCustomerGroup(customerGroup);
 
    return customerGroup;
  }
};
 
export const RoomBookingsEditForm = ({ values, handleSubmit }) => {
  const [selectedITOYear, setSelectedITOYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [noOfAttendees, setNoOfAttendees] = useState(0);
  const [location, setLocation] = useState();
  const [bookingWindow, setBookingWindow] = useState();
  const [booking, setBooking] = useState();
  const [bookingWindowDates, setBookingWindowDates] = useState();
  const [availableRooms, setAvailableRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [selectedBookingItem, setSelectedBookingItem] = useState();
  const [isBookingReady, setIsBookingReady] = useState(false);
  const [bookingItems, setBookingItems] = useState([]);
  const [duplicateBookingItems, setDuplicateBookingItems] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [idCounter, setIdCounter] = useState(0);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isDuplicateBookingItem, setIsDuplicateBookingItem] = useState(false);
  const [isOnlyRoomDifferent, setIsOnlyRoomDifferent] = useState(false);
  const [isOnlyTimeslotDifferent, setIsOnlyTimeslotDifferent] = useState(false);
  const [isSameDayDifferentRoomAndTimeslot, setIsSameDayDifferentRoomAndTimeslot] = useState(false);
  const [isRoomCapacityReached, setIsRoomCapacityReached] = useState(false);
  const [isNumberOfAttendeesZero, setIsNumberOfAttendeesZero] = useState(false);
  const [entireDayCardDisabled, setEntireDayCardDisabled] = useState(false);
  const [maxBookingHoursReached, setMaxBookingHoursReached] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [customerGroup, setCustomerGroup] = useState();
  const [roomsMapUrl, setRoomsMapUrl] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [hoursRemaining, setHoursRemaining] = useState(0);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);
  const history = useHistory();
  const attendeesRef = useRef();
  
  useEffect(() => {
    if (bookingWindowDates && bookingWindowDates.length > 0) {
        if (attendeesRef.current) {
          attendeesRef.current.focus();
        }
    }
  }, [bookingWindowDates, attendeesRef]);
 
  //TODO: This must be configured somewhere!
  const timeSlot1StartDate = new Date();
  timeSlot1StartDate.setHours(7, 0, 0);
 
  const timeSlot1EndDate = new Date();
  timeSlot1EndDate.setHours(13, 0, 0);
 
  const timeSlot2StartDate = new Date();
  timeSlot2StartDate.setHours(13, 30, 0);
 
  const timeSlot2EndDate = new Date();
  timeSlot2EndDate.setHours(19, 0, 0);
 
  const timeSlot3StartDate = new Date();
  timeSlot3StartDate.setHours(7, 0, 0);
 
  const timeSlot3EndDate = new Date();
  timeSlot3EndDate.setHours(19, 0, 0);
 
  const timeslots = [
    {
      timeOfDay: "Morning",
      duration: "7am - 1pm",
      startTime: timeSlot1StartDate,
      endTime: timeSlot1EndDate,
    },
    {
      timeOfDay: "Afternoon",
      duration: "1:30pm - 7pm",
      startTime: timeSlot2StartDate,
      endTime: timeSlot2EndDate,
    },
    {
      timeOfDay: "Entire Day",
      duration: "7am - 7pm",
      startTime: timeSlot3StartDate,
      endTime: timeSlot3EndDate,
    },
  ];
 
  useEffect(() => {
    getRoomsMapUrl(setRoomsMapUrl);
  }, []);
 
  useEffect(() => {
    if (selectedCustomerGroup) {
      fetchCustomerGroup(setCustomerGroup, selectedCustomerGroup);
    } else {
      history.push({
        pathname: `/`,
        state: {},
      });
    }
  }, [selectedCustomerGroup, setCustomerGroup]);
 
  useEffect(() => {
    getLocations(setLocations, locations, setLoading);
  }, [locations, setLocations]);
 
  useEffect(() => {
    getBooking(setBooking, values.bookingId, setLoading, setBookingWindow);
  }, [values.bookingId, setBooking, setBookingWindow]);
 
  useEffect(() => {
    getLocation(setLocation, booking?.bookingWindow.locationId, setLoading);
  }, [booking, setLocation]);
 
  useEffect(() => {
    getBookingWindowDates(
      setLoading,
      setBookingWindowDates,
      booking?.bookingWindowId,
      selectedCustomerGroup
    );
  }, [selectedCustomerGroup, setBookingWindowDates, booking?.bookingWindowId]);
 
  useEffect(() => {
    if (
      bookingWindowDates &&
      bookingWindowDates.length > 0 &&
      booking?.bookingWindowId
    ) {
      getAvailableRooms(
        setAvailableRooms,
        booking?.bookingWindowId,
        bookingWindowDates[0].fullDate,
        bookingWindowDates[bookingWindowDates.length - 1].fullDate
      );
    }
  }, [bookingWindowDates, booking?.bookingWindowId, setAvailableRooms]);
 
  useEffect(() => {
    if (!selectedCustomerGroup) {
      history.push({
        pathname: `/`,
        state: {},
      });
    }
  }, [selectedCustomerGroup]);
 
  const areDatesEqual = (date1, date2) => {
    return date1.toDateString() === date2.toDateString();
  };
 
  const areTimesEqual = (date1, date2) => {
    return (
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes() &&
      date1.getSeconds() === date2.getSeconds()
    );
  };
 
  const convertToUTC = (date) => {
    const dateToConvert = new Date(date);
 
    const utcYear = dateToConvert.getUTCFullYear();
    const utcMonth = dateToConvert.getUTCMonth();
    const utcDay = dateToConvert.getUTCDate();
    const utcHours = dateToConvert.getUTCHours();
    const utcMinutes = dateToConvert.getUTCMinutes();
    const utcSeconds = dateToConvert.getUTCSeconds();
 
    return new Date(
      utcYear,
      utcMonth,
      utcDay,
      utcHours,
      utcMinutes,
      utcSeconds
    );
  };
 
  useEffect(() => {
    if (booking && bookingWindowDates) {
      setNoOfAttendees(booking.attendeesCount);
      const newBookingItems = [];
      let totalBookedHours = 0;

      booking.bookingItems.forEach((item, counter) => {
        const firstBookingItemStartDate = new Date(item?.startTime);
        const firstBookingItemEndDate = new Date(item?.endTime);
        const timeToReturn = timeslots?.find(
          (slot) =>
            areTimesEqual(
              firstBookingItemStartDate,
              convertToUTC(new Date(slot.startTime))
            ) &&
            areTimesEqual(
              firstBookingItemEndDate,
              convertToUTC(new Date(slot.endTime))
            )
        );
 
        const firstBookingItemDate = new Date(item?.startTime);
        const dayToReturn = bookingWindowDates?.find((date) =>
          areDatesEqual(firstBookingItemDate, new Date(date.fullDate))
        );
 
        const fullDate = new Date(dayToReturn.fullDate);
 
        const startTimeNew = new Date(
          fullDate.getFullYear(),
          fullDate.getMonth(),
          fullDate.getDate(),
          timeToReturn?.startTime?.getHours(),
          timeToReturn?.startTime?.getMinutes(),
          timeToReturn?.startTime?.getSeconds()
        );
 
        const endTimeNew = new Date(
          fullDate.getFullYear(),
          fullDate.getMonth(),
          fullDate.getDate(),
          timeToReturn?.endTime?.getHours(),
          timeToReturn?.endTime?.getMinutes(),
          timeToReturn?.endTime?.getSeconds()
        );
 
        const timeSlotString = timeToReturn
          ? `${timeToReturn?.timeOfDay} (${timeToReturn?.duration})`
          : "";
        const dateString = `${moment(fullDate).format("dddd")} ${String(
          dayToReturn?.day
        ).padStart(2, "0")} ${moment(fullDate).format("MMMM")}`;
 
        const existingItem = {
          startTime: startTimeNew,
          endTime: endTimeNew,
          dateString: dateString,
          roomId: item.roomId,
          roomName: item.room.name,
          timeSlotString: timeSlotString,
          dayOfTheWeek: dayToReturn?.dayOfTheWeek,
          day: dayToReturn?.day,
          month: dayToReturn?.month,
          index: counter,
          dayObject: dayToReturn,
          timeSlotObject: timeToReturn,
          roomObject: item.room,
          bookingItemId: item.bookingItemId,
        };

        const duration = (endTimeNew - startTimeNew) / (1000 * 60 * 60);
        totalBookedHours += duration;

        newBookingItems.push(existingItem);
        setIdCounter(counter);
      });
      const maxBookingTime = bookingWindowDates[0]?.maxBookingTime;
      setHoursRemaining(maxBookingTime - totalBookedHours);
      setBookingItems(newBookingItems);
    }
  }, [booking, bookingWindowDates]);
 
  useEffect(() => {
    if (selectedDay && selectedRoom && selectedTimeSlot && noOfAttendees) {
      setIsBookingReady(true);
    }
  }, [selectedDay, selectedRoom, selectedTimeSlot, noOfAttendees]);
 
  useEffect(() => {
    if (yearOptions) {
      const currentYear = new Date().getFullYear();
      setSelectedITOYear(currentYear.toString());
    }
  }, [yearOptions]);
 
  // const promptBeforeRedirect = (active) => {
  //   if (active) {
  //     window.onbeforeunload = () => true;
  //   } else {
  //     window.onbeforeunload = null;
  //   }
  // };
 
  // useEffect(() => {
  //   promptBeforeRedirect(editing);
  // }, [editing]);
 
  const clearEditMode = () => {
    setIsEditMode(false);
  };
 
  const handleTimeSlotCardClick = (timeSlot, disabled) => {
    if (!disabled) {
      setSelectedTimeSlot(timeSlot);
 
      if (!noOfAttendees || noOfAttendees === 0)
        setIsNumberOfAttendeesZero(true);
      else setIsNumberOfAttendeesZero(false);
 
      if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
      if (isOnlyRoomDifferent) setIsOnlyRoomDifferent(false);
      if (entireDayCardDisabled) setEntireDayCardDisabled(false);
      if (errorMessage) setErrorMessage("");
    }
  };
 
  const handleDayClick = (day, disabled) => {
    if (!disabled) {
      setSelectedDay(day);
      setEditing(true);
 
      if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
      if (isOnlyRoomDifferent) setIsOnlyRoomDifferent(false);
      if (isOnlyTimeslotDifferent) setIsOnlyTimeslotDifferent(false);
      if (isSameDayDifferentRoomAndTimeslot) setIsSameDayDifferentRoomAndTimeslot(false);
      if (entireDayCardDisabled) setEntireDayCardDisabled(false);
      if (errorMessage) setErrorMessage("");
    }
  };
 
  const onRoomChange = (option) => {
    const room = location?.rooms?.find((room) => room.name === option);
 
    if (room) {
      setSelectedRoom(room);
 
      if (room.capacity < noOfAttendees) setIsRoomCapacityReached(true);
      else setIsRoomCapacityReached(false);
    }
 
    if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
    if (errorMessage) setErrorMessage("");
  };
 
  const onAttendeesChange = (noOfAttendeesString) => {
    if (selectedRoom && selectedRoom.capacity < noOfAttendeesString?.floatValue)
      setIsRoomCapacityReached(true);
    else setIsRoomCapacityReached(false);
 
    if (!noOfAttendeesString || noOfAttendeesString?.floatValue === 0)
      setIsNumberOfAttendeesZero(true);
    else setIsNumberOfAttendeesZero(false);
 
    setNoOfAttendees(noOfAttendeesString?.floatValue);
    setEditing(true);
  };
 
  const addOrUpdateBooking = () => {
    if (selectedBookingItem) {
      const fullDate = new Date(selectedDay.fullDate);
 
      const startTimeNew = new Date(
        fullDate.getFullYear(),
        fullDate.getMonth(),
        fullDate.getDate(),
        selectedTimeSlot?.startTime?.getHours(),
        selectedTimeSlot?.startTime?.getMinutes(),
        selectedTimeSlot?.startTime?.getSeconds()
      );
 
      const endTimeNew = new Date(
        fullDate.getFullYear(),
        fullDate.getMonth(),
        fullDate.getDate(),
        selectedTimeSlot?.endTime?.getHours(),
        selectedTimeSlot?.endTime?.getMinutes(),
        selectedTimeSlot?.endTime?.getSeconds()
      );
 
      const dateString = `${moment(fullDate).format("dddd")} ${String(
        selectedDay?.day
      ).padStart(2, "0")} ${moment(fullDate).format("MMMM")}`;
      const timeSlotString = `${selectedTimeSlot.timeOfDay} (${selectedTimeSlot.duration})`;
 
      const bookingItem = {
        startTime: startTimeNew,
        endTime: endTimeNew,
        dateString: dateString,
        roomId: selectedRoom.roomId,
        roomName: selectedRoom.name,
        timeSlotString: timeSlotString,
        dayOfTheWeek: selectedDay?.dayOfTheWeek,
        day: selectedDay?.day,
        month: selectedDay?.month,
        index: idCounter,
        dayObject: selectedDay,
        timeSlotObject: selectedTimeSlot,
        roomObject: selectedRoom,
      };
 
      const isDuplicate = bookingItems.some(
        (item) =>
          item.roomId === bookingItem.roomId &&
          item.dateString === bookingItem.dateString &&
          item.timeSlotString === bookingItem.timeSlotString
      );

      const isSameDayDiffRoomAndTimeslot = bookingItems.some(
        (item) =>
            item.dateString === bookingItem.dateString &&
            item.roomId !== bookingItem.roomId &&
            item.timeSlotString !== bookingItem.timeSlotString 
      );
 
      if (isDuplicate) {
        setIsDuplicateBookingItem(true);
      } else {
        setIsDuplicateBookingItem(false);
      }

      isSameDayDiffRoomAndTimeslot ? setIsSameDayDifferentRoomAndTimeslot(true) : setIsSameDayDifferentRoomAndTimeslot(false);
 
      if (!isDuplicate && bookingItems && !isSameDayDiffRoomAndTimeslot) {
        setIdCounter(idCounter + 1);
 
        const bookingItemToUpdate = bookingItems?.find(
          (item) => item.index === selectedBookingItem.index
        );
 
        const tempData = [...bookingItems];
 
        if (bookingItemToUpdate) {
          bookingItemToUpdate.startTime = startTimeNew;
          bookingItemToUpdate.endTime = endTimeNew;
          bookingItemToUpdate.dateString = dateString;
          bookingItemToUpdate.roomId = selectedRoom.roomId;
          bookingItemToUpdate.roomName = selectedRoom.name;
          bookingItemToUpdate.timeSlotString = timeSlotString;
          bookingItemToUpdate.dayOfTheWeek = selectedDay?.dayOfTheWeek;
          bookingItemToUpdate.day = selectedDay?.day;
          bookingItemToUpdate.month = selectedDay?.month;
          bookingItemToUpdate.dayObject = selectedDay;
          bookingItemToUpdate.timeSlotObject = selectedTimeSlot;
          bookingItemToUpdate.roomObject = selectedRoom;
          bookingItemToUpdate.index = selectedBookingItem.index;
 
          setBookingItems(tempData);
        }
 
        const duration = (endTimeNew - startTimeNew) / (1000 * 60 * 60);
        // const allBookingsDurations = bookingHoursSelected + duration;
 
        // setBookingHoursSelected(allBookingsDurations);
 
        if (maxBookingHoursReached) setMaxBookingHoursReached(false);

        const durationInMillis = endTimeNew - startTimeNew;

        const updatedDurationInHours = Math.floor(durationInMillis / 1000 / 60 / 60);

        const otherBookingsDuration = bookingItems
          .filter((item) => item.index !== selectedBookingItem.index) // Exclude the current booking item
          .reduce((sum, item) => {
            const itemDuration =
              (new Date(item.endTime) - new Date(item.startTime)) / (1000 * 60 * 60);
            return sum + itemDuration;
          }, 0);

        const maxBookingTime = bookingWindowDates[0]?.maxBookingTime;
        const totalBookedHours = otherBookingsDuration + updatedDurationInHours;
        const remainingTime = maxBookingTime - totalBookedHours;

        setHoursRemaining(remainingTime);

        setSelectedDay(null);
        setSelectedRoom(null);
        setSelectedTimeSlot(null);
        setIsBookingReady(false);
        setSelectedBookingItem(null);
      }
    } else {
      const fullDate = new Date(selectedDay.fullDate);
 
      const startTimeNew = new Date(
        fullDate.getFullYear(),
        fullDate.getMonth(),
        fullDate.getDate(),
        selectedTimeSlot?.startTime?.getHours(),
        selectedTimeSlot?.startTime?.getMinutes(),
        selectedTimeSlot?.startTime?.getSeconds()
      );
 
      const endTimeNew = new Date(
        fullDate.getFullYear(),
        fullDate.getMonth(),
        fullDate.getDate(),
        selectedTimeSlot?.endTime?.getHours(),
        selectedTimeSlot?.endTime?.getMinutes(),
        selectedTimeSlot?.endTime?.getSeconds()
      );
 
      const dateString = `${moment(fullDate).format("dddd")} ${String(
        selectedDay?.day
      ).padStart(2, "0")} ${moment(fullDate).format("MMMM")}`;
      const timeSlotString = `${selectedTimeSlot.timeOfDay} (${selectedTimeSlot.duration})`;
 
      const maxId =
        bookingItems.length > 0
          ? Math.max(...bookingItems.map((item) => item.index))
          : 0;
      setIdCounter(maxId + 1);
 
      const bookingItem = {
        startTime: startTimeNew,
        endTime: endTimeNew,
        dateString: dateString,
        roomId: selectedRoom.roomId,
        roomName: selectedRoom.name,
        timeSlotString: timeSlotString,
        dayOfTheWeek: selectedDay?.dayOfTheWeek,
        day: selectedDay?.day,
        month: selectedDay?.month,
        index: maxId + 1,
        dayObject: selectedDay,
        timeSlotObject: selectedTimeSlot,
        roomObject: selectedRoom,
      };
 
      const isDuplicate = bookingItems.some(
        (item) =>
          item.roomId === bookingItem.roomId &&
          item.dateString === bookingItem.dateString &&
          item.timeSlotString === bookingItem.timeSlotString
      );

      const isOnlyRoomDiff = bookingItems.some(
        (item) =>
            item.dateString === bookingItem.dateString &&
            item.timeSlotString === bookingItem.timeSlotString &&
            item.roomId !== bookingItem.roomId // Different room
      );

      const isOnlyTimeslotDiff = bookingItems.some(
        (item) =>
            item.dateString === bookingItem.dateString && // Same date
            item.roomId === bookingItem.roomId && // Same room
            item.timeSlotString !== bookingItem.timeSlotString
      );
  
      const isSameDayDiffRoomAndTimeslot = bookingItems.some(
        (item) =>
            item.dateString === bookingItem.dateString &&
            item.roomId !== bookingItem.roomId &&
            item.timeSlotString !== bookingItem.timeSlotString 
      );
 
      if (isDuplicate) {
        setIsDuplicateBookingItem(true);
      } else {
        setIsDuplicateBookingItem(false);
      }

      // same day, different room, same timeslot
    isOnlyRoomDiff ? setIsOnlyRoomDifferent(true) : setIsOnlyRoomDifferent(false);

    // same day. same room, different timeslot
    isOnlyTimeslotDiff ? setIsOnlyTimeslotDifferent(true) : setIsOnlyTimeslotDifferent(false);

    //same day, different room, different timeslot
    isSameDayDiffRoomAndTimeslot ? setIsSameDayDifferentRoomAndTimeslot(true) : setIsSameDayDifferentRoomAndTimeslot(false);
 
      if (maxBookingHoursReached) setMaxBookingHoursReached(false);

      const durationInMillis = endTimeNew - startTimeNew;

      const durationInHours = Math.floor(durationInMillis / 1000 / 60 / 60);

      const remainingTime = hoursRemaining - durationInHours;

      if(!isDuplicate && !isOnlyRoomDiff && 
        !isOnlyTimeslotDiff && !isSameDayDiffRoomAndTimeslot){
        setHoursRemaining(remainingTime);
      }
      
      if (!isDuplicate && !isOnlyRoomDiff 
        && !isOnlyTimeslotDiff && !isSameDayDiffRoomAndTimeslot) {
        setIdCounter(idCounter + 1);
 
        const updatedItems = [...bookingItems, bookingItem].sort(
          (a, b) => a.startTime - b.startTime
        );
 
        setBookingItems(updatedItems);
 
        setSelectedDay(null);
        setSelectedRoom(null);
        setSelectedTimeSlot(null);
        setIsBookingReady(false);
        setSelectedBookingItem(null);
      }
    }
    clearEditMode();
  };
 
  const clearSelection = () => {
    setSelectedDay(null);
    setSelectedRoom(null);
    setSelectedTimeSlot(null);
    setSelectedBookingItem(null);
    setIsBookingReady(false);
    setIsNumberOfAttendeesZero(false);
    setIsEditMode(false);
  };
 
  const submitBooking = async () => {
    const maxBookingTime = bookingWindowDates[0]?.maxBookingTime;

    const totalDuration = bookingItems?.reduce((total, item) => {
      const startTime = new Date(item.startTime).getTime();
      const endTime = new Date(item.endTime).getTime();
      const duration = (endTime - startTime) / (1000 * 60 * 60); // convert ms to hours
      return total + duration;
    }, 0);

    if (totalDuration > maxBookingTime) setMaxBookingHoursReached(true);
    else {
      setMaxBookingHoursReached(false);
      setSaveLoading(true);
 
      const duration = `${moment(bookingWindow.startDate).format(
        "DD/MM/YYYY"
      )} - ${moment(bookingWindow.endDate).format("DD/MM/YYYY")}`;
 
      const bookingToUpdate = {
        bookingId: booking.bookingId,
        bookingWindowId: bookingWindow.bookingWindowId,
        bookingWindowName: bookingWindow?.name,
        bookingWindowLocation: bookingWindow?.location?.name,
        bookingWindowDuration: duration,
        customerGroupId: booking.customerGroupId,
        attendeesCount: noOfAttendees,
        specialRequests: booking.specialRequests ? booking.specialRequests : "",
        bookingAttendees: booking.bookingAttendees
          ? booking.bookingAttendees
          : [],
        bookingItems: bookingItems,
      };
 
      try {
        const bookingUpdated = await updateBooking(
          bookingToUpdate,
          setErrorMessage,
          setDuplicateBookingItems
        );
        if (!bookingUpdated) {
          setSaveLoading(false);
        } else {
          setSaveLoading(false);
 
          setSelectedDay(null);
          setSelectedRoom(null);
          setBookingItems([]);
          setNoOfAttendees(0);
          setSelectedTimeSlot(null);
          setIdCounter(0);
          setEditing(false);
          setErrorMessage("");

          history.push({
            pathname: `/reservations/manage-reservation/${values?.bookingId}`,
            state: {
              from: "/reservations/edit/:bookingId",
              booking: bookingToUpdate,
              customerGroupSelected: selectedCustomerGroup,
              itoYearSelected: selectedITOYear,
            },
          });
        }
      } catch (err) {}
    }
  };
 
  const removeBookingItem = (bookingItem) => {
    const filteredBookings = bookingItems.filter(
      (item) => item.index !== bookingItem.index
    );
    setBookingItems(filteredBookings);

    const durationInMillis = bookingItem.endTime - bookingItem.startTime;
    const durationInHours = (durationInMillis / 1000 / 60 / 60);
  
    const remainingTime = hoursRemaining + durationInHours;
  
    setHoursRemaining(remainingTime);

    if (errorMessage) setErrorMessage("");
    if (maxBookingHoursReached) setMaxBookingHoursReached(false);
  };
 
  const isDayEnabled = (date) => {
    const roomAvailabilityForDate = availableRooms?.find(
      (item) => item.date === date.fullDate
    );
 
    if (
      roomAvailabilityForDate &&
      roomAvailabilityForDate.availabilityItems?.length > 0
    ) {
      return roomAvailabilityForDate?.availabilityItems?.every(
        (item) =>
          !item.isMorningAvailable &&
          !item.isAfternoonAvailable &&
          !item.isEntireDayAvailable
      );
    }
 
    return false;
  };
 
  const isRoomDisabled = (room) => {
    const roomAvailabilityForDate = availableRooms?.find((item) => {
      const availabilityDate = new Date(item.date);
      availabilityDate.setHours(0, 0, 0, 0);
      const dateToCompare = new Date(selectedDay?.fullDate);
      dateToCompare.setHours(0, 0, 0, 0);
      return availabilityDate.getTime() === dateToCompare.getTime();
    });

    const roomAvailability = roomAvailabilityForDate?.availabilityItems?.find(
      (roomItem) => roomItem.room.roomId === room.roomId
    );

    if (selectedBookingItem) {
      // Always return false for selectedBookingItem's room to ensure it's not filtered out
      if (room.roomId === selectedBookingItem.roomId) {
        return false;
      }
    }

    if (roomAvailability) {
      const isUnavailable =
        !roomAvailability.isMorningAvailable &&
        !roomAvailability.isAfternoonAvailable &&
        !roomAvailability.isEntireDayAvailable;
  
      if (isEditMode &&
          selectedTimeSlot.timeOfDay === "Entire Day" &&
          (!roomAvailability.isMorningAvailable || !roomAvailability.isAfternoonAvailable)) {
        return true;
      }
  
      return isUnavailable;
    }
 
    return false;
  };

  const isTimeslotDisabled = (time, selectedRoom) => {
    const roomAvailabilityForDate = availableRooms?.find((item) => {
      const availabilityDate = new Date(item.date);
      availabilityDate.setHours(0, 0, 0, 0);
      const dateToCompare = new Date(selectedDay?.fullDate);
      dateToCompare.setHours(0, 0, 0, 0);
      return availabilityDate.getTime() === dateToCompare.getTime();
    });
  
    const roomAvailability = roomAvailabilityForDate?.availabilityItems?.find(
      (roomItem) => roomItem?.room?.roomId === selectedRoom?.roomId
    );


    if(!selectedBookingItem){

      const isRoomDisabled = () => {
        if (roomAvailability) {
          return (
            !roomAvailability.isMorningAvailable &&
            !roomAvailability.isAfternoonAvailable
          ); // Entire Day depends on Morning and Afternoon
        }
        return false;
      };
  
      const isSameDayTimeslot = bookingItems.some(
        (item) =>
          { 
            const itemMonth = monthNames[item.startTime.getMonth()];
            const itemDay = item.startTime.getDate().toString();
            return selectedDay && itemMonth === selectedDay.month && itemDay === selectedDay.day;
          }
      );
       
      if (isRoomDisabled() || isSameDayTimeslot) {
        return true; // Disable all time slots if the room is disabled
      }

      if (hoursRemaining > 6) {
        // Disable Morning and Afternoon, enable Entire Day
        if (time?.timeOfDay !== "Entire Day") {
          return true; // Disable Morning and Afternoon
        }
        else{
          return roomAvailability && !roomAvailability.isEntireDayAvailable;
        }
      } else if (hoursRemaining === 6) {
        // Enable Morning and Afternoon based on room availability
        if (time?.timeOfDay === "Morning") {
          return !roomAvailability?.isMorningAvailable; // Enable only if morning is available
        }
        if (time?.timeOfDay === "Afternoon") {
          return !roomAvailability?.isAfternoonAvailable; // Enable only if afternoon is available
        }
        return true;
      }
      else{
        return true;
      }
    }

    if (selectedBookingItem) {
      if (selectedBookingItem.timeSlotString.includes("Entire Day")) {
        if (time?.timeOfDay === "Morning" || time?.timeOfDay === "Afternoon") {
          return true; // Disable Morning and Afternoon if the reservation is Entire Day
        }
      } else if (selectedBookingItem.timeSlotString.includes("Morning")) {
        // Disable Entire Day if a Morning slot is selected
        if (time?.timeOfDay === "Entire Day") {
          return true;
        }
        // Disable Afternoon only if the room is not available
        if (time?.timeOfDay === "Afternoon") {
          return !roomAvailability?.isAfternoonAvailable;
        }
      } else if (selectedBookingItem.timeSlotString.includes("Afternoon")) {
        // Disable Entire Day if an Afternoon slot is selected
        if (time?.timeOfDay === "Entire Day") {
          return true;
        }
        // Disable Morning only if the room is not available
        if (time?.timeOfDay === "Morning") {
          return !roomAvailability?.isMorningAvailable;
        }
      }
    }
  };
 
  const openBookingItem = (bookingItem) => {
    setSelectedDay(bookingItem.dayObject);
    setSelectedRoom(bookingItem.roomObject);
    setSelectedTimeSlot(bookingItem.timeSlotObject);
    setSelectedBookingItem(bookingItem);
    setIsEditMode(true);
  };
 
  const closeConfirmationModal = (confirmationChoice) => async () => {
    setConfirmationLoading(true);
    if (confirmationChoices.confirm === confirmationChoice) {
      history.push({
        pathname: `/reservations/manage-reservation/${values?.bookingId}`,
        state: {
          from: "/reservations/edit/:bookingId",
          booking: booking,
          customerGroupSelected: selectedCustomerGroup,
          itoYearSelected: selectedITOYear,
        },
      });
    }
    setDeleteConfirmationModalOpen(false);
    setConfirmationLoading(false);
  };
 
  const handleImageModalOpen = () => setIsImageModalOpen(true);
  const handleImageModalClose = () => setIsImageModalOpen(false);
 
  return (
    <LoaderComponent loading={loading}>
      <Form onSubmit={handleSubmit}>
        <ConfirmationModal
          open={deleteConfirmationModalOpen}
          closeModal={closeConfirmationModal}
          confirmLoading={confirmationLoading}
          heading="Cancel Reservation"
          content={
            <div className="div-booking-item">
              <Unicons.UilExclamationCircle color={"#C62931"} size={25} /> You
              are about to cancel this reservation and lose all the changes. Please
              confirm that you would like to perform this action.
            </div>
          }
        />
        <Helmet>
          <title>De Beers Sigth Room Reservation Portal</title>
        </Helmet>
        <Grid>
          <Grid.Row className="customer-selection-header">
            <Grid.Column
              computer={4}
              mobile={16}
              className="customer-group-heading"
            >
              Sightholder: {customerGroup?.name}
            </Grid.Column>
            <Grid.Column computer={4} mobile={16}></Grid.Column>
            <Grid.Column computer={16} mobile={16}>
              <div className="border"></div>
            </Grid.Column>
          </Grid.Row>
          {bookingWindowDates && bookingWindowDates.length > 0 ? (
            <>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <StandardSmallHeading>
                    {bookingWindow?.name}
                  </StandardSmallHeading>
                  <StandardSubheading>Reserve your Sight Room</StandardSubheading>
                  <br></br>
                  <Form>
                    <Form.Group width="equal" fluid>
                      <div className="div-col">
                        <Form.Field>
                          <label className="label-color">Location</label>
                        </Form.Field>
                        <Form.Field className="location-field">
                          {bookingWindow?.location?.name}
                        </Form.Field>
                      </div>
 
                      <Form.Field className={`eight wide field ${isNumberOfAttendeesZero || isRoomCapacityReached ? 'error' : ''}`} >
                        <label>Total Attendees</label>
                        <NumericFormat
                          name="noOfAttendees"
                          placeholder="Enter total attendees"
                          getInputRef={attendeesRef}
                          width={8}
                          className="custom-attendee-input input-field-border"
                          isAllowed={(values) => {
                            const { floatValue } = values;
                            return floatValue >= 0 || !floatValue;
                          }}
                          onValueChange={(value) => onAttendeesChange(value)}
                          decimalScale={0}
                          value={noOfAttendees}
                        />
                      </Form.Field>
                      {isNumberOfAttendeesZero && (
                        <Popup
                          content={
                            "The total attendees must be a number greater than zero."
                          }
                          Icon={Unicons.UilExclamationCircle}
                          iconClassNames="error-info-icon"
                        />
                      )}
                    </Form.Group>
                  </Form>
                  <StandardSmallHeading>Reservation Selection</StandardSmallHeading>
                  <Segment className="segment-padding-left">
                    <p className="p-text-colour">Select a day</p>
                    <Grid>
                      <Grid.Row>
                        {bookingWindowDates?.map((date, index) => (
                          <Grid.Column computer={3} tablet={5} mobile={5}>
                            <BookingDayCard
                              date={date}
                              handleDayClick={handleDayClick}
                              selectedDay={selectedDay}
                              disabled={isDayEnabled(date)}
                              tooltipMessage={"Day is fully booked."}
                            ></BookingDayCard>
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                    <br />
                    <div>
                      <Grid>
                        <Grid.Row columns={2}>
                        <Grid.Column>
                          <Label basic className="room-map-label">
                            Select a Sight Room
                          </Label>
                          <SematicPopup
                            content="Max capacity is based on health & safety requirements."
                            trigger={
                              <span>
                                <Unicons.UilInfoCircle className="info-icon" />
                              </span>
                            }
                          />
                        </Grid.Column>
                          <Grid.Column textAlign="right">
                            <Label
                              basic
                              onClick={handleImageModalOpen}
                              className="view-map"
                            >
                              View Sight Room Map
                            </Label>
                            <Modal
                              open={isImageModalOpen}
                              onClose={handleImageModalClose}
                              size="large"
                              closeIcon
                            >
                              <Header>Sight Room Map</Header>
                              <Modal.Content>
                                <iframe
                                  src={roomsMapUrl}
                                  width="100%"
                                  height="630px"
                                  title="Rooms Map"
                                />
                              </Modal.Content>
                            </Modal>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          {location &&
                            location.rooms?.length > 0 &&
                            location.rooms
                            ?.filter((room) => !isRoomDisabled(room))
                            .map((room, index) => (
                              <Grid.Column
                                className="column-room-padding"
                                computer={8}
                                tablet={8}
                                mobile={16}
                              >
                                <Segment
                                  className={
                                    isRoomDisabled(room)
                                      ? "selected-room isNotSelected disabled"
                                      : selectedRoom?.name === room.name
                                      ? "selected-room isSelected"
                                      : "selected-room isNotSelected"
                                  }
                                >
                                  <Radio
                                    label={
                                      room.name +
                                      ` (Max ${room.capacity} attendee(s))`
                                    }
                                    value={room.name}
                                    checked={selectedRoom?.name === room.name}
                                    onChange={() => onRoomChange(room.name)}
                                    className="room-name-width"
                                  />
                                </Segment>
                              </Grid.Column>
                            ))}
                        </Grid.Row>
                      </Grid>
                      <br />
                    </div>
 
                    <div>
                      <p className="p-text-colour">Select a time slot</p>
                      <Grid>
                        <Grid.Row>
                          {timeslots &&
                            timeslots?.length > 0 &&
                            timeslots?.map((time, index) => (
                              <Grid.Column computer={5} tablet={5} mobile={16}>
                                <BookingTimeSlotCard
                                  handleTimeSlotCardClick={
                                    handleTimeSlotCardClick
                                  }
                                  selectedTimeSlot={selectedTimeSlot}
                                  timeslot={time}
                                  disabled={isTimeslotDisabled(
                                    time,
                                    selectedRoom
                                  )}
                                  tooltipMessage={
                                    "The time slot is not available"
                                  }
                                ></BookingTimeSlotCard>
                              </Grid.Column>
                            ))}
                        </Grid.Row>
                      </Grid>
                      <br />
                    </div>
                    {isSameDayDifferentRoomAndTimeslot && (
                      <Message error visible>
                        You cannot book the same day for a different room and a different timeslot.
                      </Message>
                    )}
                    {isOnlyTimeslotDifferent && (
                      <Message error visible>
                        You cannot book the same room on the same day with overlapping timeslots.
                      </Message>
                    )}
                    {isOnlyRoomDifferent && (
                       <Message error visible>
                        You cannot book a different room for the same date and timeslot.
                      </Message>
                    )}
                    {isDuplicateBookingItem && (
                      <Message error visible>
                        The same reservation was already added to the summary.
                      </Message>
                    )}
                    {isRoomCapacityReached && (
                      <Message
                        className="error-message-attendee-total"
                        error
                        visible
                      >
                        The total number of attendees are more than the selected
                        room capacity. The room capacity for{" "}
                        {selectedRoom?.name} is {selectedRoom?.capacity}.
                      </Message>
                    )}
                    {isNumberOfAttendeesZero && (
                      <Message
                        className="error-message-attendee-total"
                        error
                        visible
                      >
                        The total attendees must be a number greater than zero.
                      </Message>
                    )}
                    <div className="right-text">
                      <Button
                        secondary
                        onClick={() => clearSelection()}
                        className="btn-secondary"
                      >
                        Clear Selection
                      </Button>
                      <Button
                        disabled={
                          !isBookingReady ||
                          isRoomCapacityReached ||
                          isNumberOfAttendeesZero ||
                          isDuplicateBookingItem ||
                          isOnlyRoomDifferent ||
                          isOnlyTimeslotDifferent ||
                          isSameDayDifferentRoomAndTimeslot
                        }
                        primary
                        onClick={() => addOrUpdateBooking()}
                        className="btn-primary"
                      >
                        {isEditMode ? "Update Reservation" : "Add Reservation"}
                      </Button>
                    </div>
                  </Segment>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <br />
                  <StandardSmallHeading>Reservation Summary</StandardSmallHeading>
                  <br></br>
                  {bookingItems && bookingItems.length > 0 ? (
                    <Segment>
                      <BookingItems
                        bookingItems={bookingItems}
                        removeBookingItem={removeBookingItem}
                        openBookingItem={openBookingItem}
                        selectedItem={selectedBookingItem}
                        isEditButtonVisible={true}
                        duplicateBookingItems={duplicateBookingItems}
                      ></BookingItems>
                    </Segment>
                  ) : (
                    <Segment className="segment-padding">
                      <p>Your reservations will be listed here</p>
                    </Segment>
                  )}
                  {maxBookingHoursReached && (
                    <Message error visible>
                      The reservation items added will exceed the maximum of number
                      of {bookingWindowDates[0]?.maxBookingTime} hours
                      allocated.{" "}
                    </Message>
                  )}
                  {errorMessage && (
                    <Message error visible>
                      There is already a reservation for the day(s), room(s) and
                      time slot(s).
                    </Message>
                  )}
                  <Button
                    disabled={
                      bookingItems?.length === 0 ||
                      maxBookingHoursReached ||
                      errorMessage
                    }
                    primary
                    floated="right"
                    onClick={submitBooking}
                    loading={saveLoading}
                    className="btn-primary"
                  >
                    Submit Reservation
                  </Button>
                  <Button
                    secondary
                    floated="right"
                    onClick={() => setDeleteConfirmationModalOpen(true)}
                    className="btn-secondary"
                  >
                    Cancel
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row></Grid.Row>
              <Grid.Row></Grid.Row>
              <Grid.Row></Grid.Row>
            </>
          ) : (
            <Message
              icon="warning circle"
              header={`Reservations Closed`}
              content={` Reservations are closed for the selected Sightholder.`}
            />
          )}
        </Grid>
      </Form>
    </LoaderComponent>
  );
};