import {
  CustomerGroupsContext,
  SelectedCustomerContext,
  SelectedCustomerGroupContext,
} from "../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { LoginCallback, Security } from "@okta/okta-react";
import React, { Suspense, lazy, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import AppLayout from "../components/AppLayout/AppLayout";
import { Cookies } from "./login/Cookies";
import { GetOktaConfig } from "../lib/oktaConfig";
import { LoaderComponent } from "../common/LoaderComponent";
import { LoggedInWrapper } from "../common/LoggedInWrapper/LoggedInWrapper";
import Logout from "./login/Logout";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Profile } from "./profile/Profile";
import LoginWrapper from "./login/LoginWrapper";
import { TermsConditions } from "./login/TermsConditions";
import { PrivacyPolicy } from "./login/PrivacyPolicy";
import { tokenUserPermissions } from "../lib/tokenUserPermissions";
import { tokenPermissions } from "../lib/tokenPermissions";
import { RoomBookingsContainer } from "./room-bookings/RoomBookingsContainer";
import { RoomBookingsCreateContainer } from "./room-bookings/RoomBookingsCreateContainer";
import { BookingCompleted } from "./room-bookings/BookingCompleted";
import { RoomBookingsManageContainer } from "./room-bookings/RoomBookingsManageContainer";
import { RoomBookingsEditContainer } from "./room-bookings/RoomBookingsEditContainer";
import { UpcomingAndPastBookings } from "./room-bookings/UpcomingAndPastBookings";
import SignIn from "./login/SignIn";
import EmailUnsubscribePage from "./profile/EmailUnsubscribePage";

const OktaErrorHandler = lazy(() => import("./login/OktaErrorHandler"));

const NotAuthenticatedContainer = lazy(() =>
  import("./not-authenticated/NotAuthenticatedContainer")
);
const NotAuthorisedContainer = lazy(() =>
  import("./not-authorised/NotAuthorisedContainer")
);
const NotFoundContainer = lazy(() => import("./not-found/NotFoundContainer"));

const requireAuth = (Module, permission) => (props) => {
  return (
    <LoggedInWrapper key={props.location.pathname} permission={permission}>
      <Module {...props} />
    </LoggedInWrapper>
  );
};

export const Routes = () => {
  const [customerGroups, setCustomerGroups] = useState([]);
  const [selectedCustomerGroup, setSelectedCustomerGroup] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const customerGroupsValue = {
    customerGroups: customerGroups,
    setCustomerGroups: setCustomerGroups,
  };
  const selectedCustomerGroupValue = {
    selectedCustomerGroup: selectedCustomerGroup,
    setSelectedCustomerGroup: setSelectedCustomerGroup,
  };
  const selectedCustomerValue = {
    selectedCustomer: selectedCustomer,
    setSelectedCustomer: setSelectedCustomer,
  };

  const loginCallback = () => {
    return <LoginCallback errorComponent={OktaErrorHandler} />;
  };

  const history = useHistory();
  const onAuthRequired = () => {
    history.push("/login");
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "", window.location.origin));
  };

  const oktaAuth = new OktaAuth({ onAuthRequired, ...GetOktaConfig() });
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {/* This is not a mistake Route path="/okta-login" must live outside of AppLayout otherwise bad things happen */}
      {/* <Route path="/okta-login" render={loginCallback} /> */}
      <Route path="/okta-login" element={<LoginCallback />} />
      <Route exact path="/logout" component={Logout} />
      <LoginWrapper>
        <AppLayout>
          <CustomerGroupsContext.Provider value={customerGroupsValue}>
            <SelectedCustomerGroupContext.Provider
              value={selectedCustomerGroupValue}
            >
              <SelectedCustomerContext.Provider value={selectedCustomerValue}>
                <Suspense fallback={<LoaderComponent loading={true} />}>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={requireAuth(
                        RoomBookingsContainer,
                        tokenPermissions.USER
                      )}
                    />

                    <Route path="/login" render={() => <SignIn />} />
                    <Route
                      exact
                      path="/termsconditions"
                      component={TermsConditions}
                    />
                    <Route exact path="/cookies" component={Cookies} />
                    <Route exact path="/privacypolicy" component={PrivacyPolicy} />

                    <Route
                      exact
                      path="/not-authenticated"
                      component={NotAuthenticatedContainer}
                    />
                    <Route
                      exact
                      path="/not-authorised"
                      component={NotAuthorisedContainer}
                    />
                    <Route
                      exact
                      path="/profile"
                      render={requireAuth(Profile, tokenPermissions.USER)}
                    />
                    <Route
                      exact
                      path="/reservations"
                      render={requireAuth(
                        RoomBookingsContainer,
                        tokenPermissions.USER
                      )}
                    />
                    <Route
                      path="/reservations/create/:bookingWindowId"
                      render={requireAuth(
                        RoomBookingsCreateContainer,
                        tokenPermissions.USER
                      )}
                    />
                    <Route
                      path="/reservations/reservation-created-success"
                      render={requireAuth(
                        (props) => BookingCompleted(props),
                        tokenPermissions.USER
                      )}
                    />
                    <Route
                      path="/reservations/manage-reservation/:bookingId"
                      render={requireAuth(
                        RoomBookingsManageContainer,
                        tokenPermissions.USER
                      )}
                    />
                    <Route
                      path="/reservations/edit/:bookingId"
                      render={requireAuth(
                        RoomBookingsEditContainer,
                        tokenPermissions.USER
                      )}
                    />
                    <Route
                      path="/reservations/past"
                      render={requireAuth(
                        UpcomingAndPastBookings,
                        tokenPermissions.USER
                      )}
                    />
                    <Route
                      path="/profile/unsubscribe/:reference"
                      render={requireAuth(
                        EmailUnsubscribePage,
                        tokenPermissions.EMAIL_UNSUBSCRIBE
                      )}
                    />
                    <Route component={NotFoundContainer} />
                  </Switch>
                </Suspense>
              </SelectedCustomerContext.Provider>
            </SelectedCustomerGroupContext.Provider>
          </CustomerGroupsContext.Provider>
        </AppLayout>
      </LoginWrapper>
    </Security>
  );
};
