import { notifyError } from "./notifications";

const { createAxios } = require("./axiosWrapper");

const bookingUrl = `${process.env.REACT_APP_BOOKINGS_API_URL}/api/BookingWindows`;

const axios = () => createAxios(bookingUrl);

export const getBookingWindowDataAsync = async (selectedItoYear) => {
  try {
    const requestUrl = `get-booking-windows-for-ito-year?itoYear=${selectedItoYear}`;

    const bookingWindows = await axios().get(requestUrl);

    return bookingWindows.data ? bookingWindows.data : [];
  } catch (err) {
    notifyError("Failed to get the reservation windows.", err);
  }
};

export const getBookingWindowForCustomerGroupAsync = async (
  customerGroupId
) => {
  try {
    const requestUrl = `get-booking-windows-for-customer-group?customerGroupId=${customerGroupId}`;

    const bookingWindows = await axios().get(requestUrl);

    return bookingWindows.data ? bookingWindows.data : [];
  } catch (err) {
    notifyError("Failed to get the reservation windows.", err);
  }
};

export const getBookingWindowAsync = async (windowId) => {
  try {
    const requestUrl = `${windowId}`;

    const bookingWindow = await axios().get(requestUrl);

    return bookingWindow.data ? bookingWindow.data : [];
  } catch (err) {
    notifyError("Failed to get the reservation window.", err);
  }
};

export const getBookingWindowDatesAsync = async (windowId, customerGroupId) => {
  try {
    const requestUrl = `get-booking-window-dates?windowId=${windowId}&customerGroupId=${customerGroupId}`;

    const bookingWindowDates = await axios().get(requestUrl);

    return bookingWindowDates.data ? bookingWindowDates.data : [];
  } catch {
    //TODO: Handle this better. Should ideally not throw error on front end if no booking openings were found.
    //notifyError("Failed to get the reservation opening.", err);
  }
};
