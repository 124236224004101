import { isNil } from "lodash";

const { createAxios } = require("./axiosWrapper");
const emailUnsubscribeUrl = `${process.env.REACT_APP_EMAIL_API_URL}/api/Unsubscribe`;
const axios = () => createAxios(emailUnsubscribeUrl);

export const isEmailUnsubscribed = async ({emailAddress, setEmailPreference}) => {
  const isEmailSubscribedEndpoint = `is-unsubscribed/${emailAddress}`
  const response = await axios().get(isEmailSubscribedEndpoint);
  const isUnsubscribed = !isNil(response.data) ? !response.data : false;

  setEmailPreference(isUnsubscribed);
};

export const unsubscribe = async (unsubscribeReference) => {
  const unsubscribeEndpoint = `unsubscribe/${unsubscribeReference}`
  const response = await axios().post(unsubscribeEndpoint, {}, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data ? response.data : [];
};

export const unsubscribeEmail = async ({emailAddress, setLoading, setRefreshEmailPreference}) => {
  setLoading(true);

  const unsubscribeEmailEndpoint = `unsubscribe/email/${emailAddress}`
  await axios().post(unsubscribeEmailEndpoint, {}, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  setLoading(false);
  setRefreshEmailPreference(true);
};

export const resubscribeEmail = async ({emailAddress, setLoading, setRefreshEmailPreference}) => {
  setLoading(true);

  const resubscribeEndpoint = `resubscribe/${emailAddress}`
  await axios().post(resubscribeEndpoint, {}, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  
  setLoading(false);
  setRefreshEmailPreference(true);
};