import { storageKeys } from "./storageKeys";
// import jwt from "jsonwebtoken";
import { jwtDecode } from "jwt-decode";
import { createAxios } from "./axiosWrapper";
import { notifyError } from "./notifications";

const authenticationUrl = `${process.env.REACT_APP_AUTHENTICATION_API_URL}/api`;
const scopesKey = process.env.REACT_APP_JWT_SCOPES_KEY;

const axios = () => createAxios(authenticationUrl);

export const isLoggedIn = () => {
  const validAccessToken = localStorage.getItem(storageKeys.ACCESS_TOKEN);
  const accessTokenExpiresAt = localStorage.getItem(
    storageKeys.ACCESS_TOKEN_EXPIRES_AT
  );
  const now = Date.now() / 1000;
  if (validAccessToken && accessTokenExpiresAt > now) {
    return true;
  }
  return false;
};

export const clearAuthStorage = () => {
  for (const property in storageKeys) {
    if (storageKeys.hasOwnProperty(property)) {
      localStorage.removeItem(storageKeys[property]);
    }
  }
};

export const logout = () => {
  window.location.replace("/logout");
};

export const logoutWithCallback = () => {
  clearAuthStorage();
  window.location.replace(`/login?callback=${encodeURI(window.location.href)}`);
};

export const getToken = () => {
  return localStorage.getItem(storageKeys.ACCESS_TOKEN);
};

export const getPermissions = () => {
  const validToken = getToken();
  if (!validToken) {
    return [];
  }
  const payload = jwtDecode(validToken);
  const scopes = payload[scopesKey];
  return scopes || [];
};

export const handleOktaToken = async (oktaToken) => {
  const response = await axios().post(
    `${authenticationUrl}/Authentication/authenticate/okta`,
    {token: oktaToken}
  );
  const token = response.data.token;
  const { exp } = jwtDecode(token);
  localStorage.setItem(storageKeys.ACCESS_TOKEN, token);
  localStorage.setItem(storageKeys.ACCESS_TOKEN_EXPIRES_AT, exp);
};

export const userNameCache = (userName) => {
  if (userName) {
    localStorage.setItem(storageKeys.USERNAME, userName);
  }

  return localStorage.getItem(storageKeys.USERNAME);
};

export const getUserId = () => {
  const validToken = getToken();
  if (validToken) {
    const payload = jwtDecode(validToken);
    return payload.user_id;
  }
};

export const setUserNameCache = async () => {
  const userId = getUserId();
    if (userId) {
    const userInformation = await axios().get(`/Users/${userId}`);
    if (userInformation?.data) {
      userNameCache(
        `${userInformation?.data.firstName} ${userInformation?.data.lastName}`
      );
    }
  }
};

export const getUserProfile = async ({ setUserInformation, setLoading }) => {
  const userId = getUserId();

  if (userId) {
    setLoading(true);
      try {
      const userInformation = await axios().get(`/Users/${userId}`);
      setUserInformation(userInformation.data);
    } catch (err) {
      notifyError("Could not fetch user profile information", err);
    } finally {
      setLoading(false);
    }
  }
};