import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { LoaderComponent } from "../../common/LoaderComponent";
import { validateRole } from "../../lib/validateRole";
import { LoginContext } from "../../modules/login/LoginContext";

export const LoggedInWrapper = ({ children, permission }) => {
  const [checkingLoggedInState, setCheckingLoggedInState] = useState(true);
  const [authorised, setAuthorised] = useState(false);
  const { loginState } = useContext(LoginContext);

  useEffect(() => {
    if (loginState.isLoggedIn) {
      const authorised = validateRole(permission);
      setAuthorised(authorised);
    }

    setCheckingLoggedInState(false);
  }, [loginState, permission]);

  if (checkingLoggedInState || loginState.isPending) {
    return <LoaderComponent loading={true} message={"A few more seconds..."} />;
  }

  if (loginState.isLoggedIn) {
    if (authorised) {
      return children;
    }
    return <Redirect to={"/not-authorised"} />;
  }

    if (window.location.pathname === "/") {
        return <Redirect to={`/login`} />;
    }

  return <Redirect to={`/login?callback=${encodeURI(window.location.href)}`} />;
};
