import "./RoomBookingsCreateContainer.scss";
import React, { useContext, useEffect, useState, useRef } from "react";
import Helmet from "react-helmet";
import {
  Popup,
  Grid,
  Segment,
  Button,
  Form,
  Radio,
  Message,
  Label,
  Modal,
  Header
} from "semantic-ui-react";
import * as Unicons from "@iconscout/react-unicons";
import { SelectedCustomerGroupContext } from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import BookingDayCard from "./Cards/BookingDayCard";
import BookingTimeSlotCard from "./Cards/BookingTimeSlotCard";
import { getLocationsAsync, getLocationAsync } from "../../lib/apiLocations";
import { getBookingWindowDatesAsync } from "../../lib/apiBookingWindows";
import { getBookingWindowAsync } from "../../lib/apiBookingWindows";
import { getRoomsMapUrl } from "../../lib/apiRooms";
import { createBooking, getAvailableRoomsAsync } from "../../lib/apiBookings";
import BookingItems from "./BookingItems";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { getCustomerGroup } from "../../lib/apiCustomerGroups";
import { NumericFormat } from "react-number-format";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";
import {
  ConfirmationModal,
  confirmationChoices,
} from "../../common/ConfirmationModal/ConfirmationModal";
import { LoaderComponent } from "../../common/LoaderComponent";
 
/*
TODO: Clean up file:
- NEED TO MOVE TO INDIVIDUAL STYLESHEETS.
- NEED TO DOUBLE CHECK THAT ALL STATE VARIABLES ARE NEEDED
- NEED TO ALSO CHECK THAT COMPONENTS MAKE SENSE
- CATER FOR MOBILE
- ADD SIGNAL R AND CHECK ROOM AVAILABILITY IN REAL TIME
- ADD VALIDATIONS TO ENSURE YOU CANNOT BOOK A DAY IN THE PAST
*/
 
const getLocations = async (setLocations, locations, setLoading) => {
  try {
    if (locations == null || locations?.length === 0) {
      setLoading(true);
      const locationResponse = await getLocationsAsync();
      if (locationResponse && locationResponse.length > 0) {
        setLocations(locationResponse);
      }
    }
  } finally {
    setLoading(false);
  }
};
 
const getLocation = async (setLocation, location, setLoading) => {
  try {
    if (location) {
      setLoading(true);
      const locationResponse = await getLocationAsync(location);
      if (locationResponse) {
        setLocation(locationResponse);
      }
    }
  } finally {
    setLoading(false);
  }
};
 
const getBookingWindow = async (
  setBookingWindow,
  bookingWindowId,
  setLoading
) => {
  try {
    setLoading(true);
    const bookingWindowResponse = await getBookingWindowAsync(bookingWindowId);
    if (bookingWindowResponse) {
      setBookingWindow(bookingWindowResponse);
    }
  } finally {
    setLoading(false);
  }
};
 
const getBookingWindowDates = async (
  setLoading,
  setBookingOpeningDates,
  bookingWindowId,
  customerGroupId
) => {
  setLoading(true);
  if (bookingWindowId) {
    const data = await getBookingWindowDatesAsync(bookingWindowId, customerGroupId);
 
    await setBookingOpeningDates(data);
  }
 
  setLoading(false);
};
 
const getAvailableRooms = async (
  setAvailableRooms,
  bookingWindowId,
  startDate,
  endDate
) => {
  if (bookingWindowId) {
    const data = await getAvailableRoomsAsync(
      bookingWindowId,
      startDate,
      endDate
    );
 
    await setAvailableRooms(data);
  }
};
 
const fetchCustomerGroup = async (setCustomerGroup, customerGroupId) => {
  if (customerGroupId) {
    const customerGroup = await getCustomerGroup(customerGroupId);
    setCustomerGroup(customerGroup);
 
    return customerGroup;
  }
};
 
export const RoomBookingsCreateContainer = ({
  match: {
    params: { bookingWindowId },
  },
}) => {
  const [selectedITOYear, setSelectedITOYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [noOfAttendees, setNoOfAttendees] = useState(0);
  const [location, setLocation] = useState();
  const [bookingWindow, setBookingWindow] = useState();
  const [bookingWindowDates, setBookingWindowDates] = useState([]);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [isBookingSelectionVisible, setIsBookingSelectionVisible] =
    useState(false);
  const [isRoomPanelVisible, setIsRoomPanelVisible] = useState(false);
  const [isTimeSlotPanelVisible, setIsTimeSlotPanelVisible] = useState(false);
  const [isBookingReady, setIsBookingReady] = useState(false);
  const [bookingItems, setBookingItems] = useState([]);
  const [duplicateBookingItems, setDuplicateBookingItems] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [customerGroup, setCustomerGroup] = useState();
  const [idCounter, setIdCounter] = useState(0);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isLocationPopulated, setIsLocationPopulated] = useState(false);
  const [isDuplicateBookingItem, setIsDuplicateBookingItem] = useState(false);
  const [isOnlyRoomDifferent, setIsOnlyRoomDifferent] = useState(false);
  const [isOnlyTimeslotDifferent, setIsOnlyTimeslotDifferent] = useState(false);
  const [isSameDayDifferentRoomAndTimeslot, setIsSameDayDifferentRoomAndTimeslot] = useState(false);
  const [isRoomCapacityReached, setIsRoomCapacityReached] = useState(false);
  const [isNumberOfAttendeesZero, setIsNumberOfAttendeesZero] = useState(false);
  const [entireDayCardDisabled, setEntireDayCardDisabled] = useState(false);
  const [maxBookingHoursReached, setMaxBookingHoursReached] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [roomsMapUrl, setRoomsMapUrl] = useState("");
  const [hoursRemaining, setHoursRemaining] = useState(null);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    const maxBookingTime = bookingWindowDates?.[0]?.maxBookingTime || 0;
    setHoursRemaining(maxBookingTime);
  }, [bookingWindowDates]);

  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);
  const history = useHistory(); 
  const attendeesRef = useRef();
 
  useEffect(() => {
    if (bookingWindowDates && bookingWindowDates.length > 0) {
        if (attendeesRef.current) {
          attendeesRef.current.focus();
        }
    }
  }, [bookingWindowDates, attendeesRef]);
  
  useEffect(() => {
    getRoomsMapUrl(setRoomsMapUrl);
  }, []);
 
  useEffect(() => {
    if (selectedCustomerGroup) {
      fetchCustomerGroup(setCustomerGroup, selectedCustomerGroup);
    } else {
      history.push({
        pathname: `/`,
        state: {},
      });
    }
  }, [selectedCustomerGroup, setCustomerGroup]);
 
  useEffect(() => {
    if (
      bookingWindowDates &&
      bookingWindowDates.length > 0 &&
      bookingWindowId
    ) {
      getAvailableRooms(
        setAvailableRooms,
        bookingWindowId,
        bookingWindowDates[0].fullDate,
        bookingWindowDates[bookingWindowDates.length - 1].fullDate
      );
    }
  }, [bookingWindowDates, bookingWindowId, setAvailableRooms]);
 
  useEffect(() => {
    if (!selectedCustomerGroup) {
      history.push({
        pathname: `/`,
        state: {},
      });
    }
  }, [selectedCustomerGroup]);
 
  useEffect(() => {
    getLocations(setLocations, locations, setLoading);
  }, [locations, setLocations]);
 
  useEffect(() => {
    getLocation(setLocation, selectedLocation, setLoading);
    getBookingWindow(setBookingWindow, bookingWindowId, setLoading);
  }, [selectedLocation, setLocation, bookingWindowId, setBookingWindow]);
 
  useEffect(() => {
    getBookingWindowDates(setLoading, setBookingWindowDates, bookingWindowId, selectedCustomerGroup);
  }, [selectedCustomerGroup, setBookingWindowDates, bookingWindowId]);
 
  useEffect(() => {
    if (selectedDay && selectedRoom && selectedTimeSlot && noOfAttendees) {
      setIsBookingReady(true);
    }
  }, [
    selectedLocation,
    selectedDay,
    selectedRoom,
    selectedTimeSlot,
    noOfAttendees,
  ]);
 
  useEffect(() => {
    if (yearOptions) {
      const currentYear = new Date().getFullYear();
      setSelectedITOYear(currentYear.toString());
    }
  }, [yearOptions]);
 
  useEffect(() => {
    if (locations) {
      setLocationOptions(
        locations.map((location) => {
          return {
            name: location.name,
            key: location.locationId,
            text: location.name,
            value: location.locationId,
          };
        })
      );
 
      setIsLocationPopulated(true);
    }
  }, [locations, setLocationOptions]);
  
  //TODO: This must be configured somewhere!
  const timeSlot1StartDate = new Date();
  timeSlot1StartDate.setHours(7, 0, 0);
 
  const timeSlot1EndDate = new Date();
  timeSlot1EndDate.setHours(13, 0, 0);
 
  const timeSlot2StartDate = new Date();
  timeSlot2StartDate.setHours(13, 30, 0);
 
  const timeSlot2EndDate = new Date();
  timeSlot2EndDate.setHours(19, 0, 0);
 
  const timeSlot3StartDate = new Date();
  timeSlot3StartDate.setHours(7, 0, 0);
 
  const timeSlot3EndDate = new Date();
  timeSlot3EndDate.setHours(19, 0, 0);
 
  const timeslots = [
    {
      timeOfDay: "Morning",
      duration: "7am - 1pm",
      startTime: timeSlot1StartDate,
      endTime: timeSlot1EndDate,
    },
    {
      timeOfDay: "Afternoon",
      duration: "1:30pm - 7pm",
      startTime: timeSlot2StartDate,
      endTime: timeSlot2EndDate,
    },
    {
      timeOfDay: "Entire Day",
      duration: "7am - 7pm",
      startTime: timeSlot3StartDate,
      endTime: timeSlot3EndDate,
    },
  ];
 
  const handleTimeSlotCardClick = (timeSlot, disabled) => {
    if (!disabled) {
      setSelectedTimeSlot(timeSlot);
 
      if (!noOfAttendees || noOfAttendees === 0)
        setIsNumberOfAttendeesZero(true);
      else setIsNumberOfAttendeesZero(false);
 
      if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
      if (entireDayCardDisabled) setEntireDayCardDisabled(false);
      if (errorMessage) setErrorMessage("");
    }
  };
 
  const handleDayClick = (day, disabled) => {
    if (!disabled) {
      setSelectedDay(day);
      setIsRoomPanelVisible(true);
      setEditing(true);
 
      if (location) setIsLocationPopulated(true);
      else setIsLocationPopulated(false);
 
      if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
      if (entireDayCardDisabled) setEntireDayCardDisabled(false);
      if (isOnlyRoomDifferent) setIsOnlyRoomDifferent(false);
      if (isOnlyTimeslotDifferent) setIsOnlyTimeslotDifferent(false);
      if (isSameDayDifferentRoomAndTimeslot) setIsSameDayDifferentRoomAndTimeslot(false);
      if (errorMessage) setErrorMessage("");
    }
  };
 
  const onRoomChange = (option) => {
    setIsTimeSlotPanelVisible(true);
 
    const room = locations[0]?.rooms?.find((room) => room.name === option);
 
    if (room) {
      setSelectedRoom(room);
 
      if (room.capacity < noOfAttendees) setIsRoomCapacityReached(true);
      else setIsRoomCapacityReached(false);
    }
 
    if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
    if (isOnlyTimeslotDifferent) setIsOnlyRoomDifferent(false);
    if (errorMessage) setErrorMessage("");
  };
 
  const onLocationChange = (location) => {
    setSelectedLocation(location);
    setIsLocationPopulated(true);
    setEditing(true);
 
    if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
    if (isOnlyRoomDifferent) setIsOnlyRoomDifferent(false);
    if (isOnlyTimeslotDifferent) setIsOnlyTimeslotDifferent(false);
    if (entireDayCardDisabled) setEntireDayCardDisabled(false);
    if (errorMessage) setErrorMessage("");
  };
 
  const onAttendeesChange = (noOfAttendeesString) => {
    if (selectedRoom && selectedRoom.capacity < noOfAttendeesString?.floatValue)
      setIsRoomCapacityReached(true);
    else setIsRoomCapacityReached(false);
 
    if (!noOfAttendeesString || noOfAttendeesString?.floatValue === 0)
      setIsNumberOfAttendeesZero(true);
    else setIsNumberOfAttendeesZero(false);
 
    setNoOfAttendees(noOfAttendeesString?.floatValue);
    setIsBookingSelectionVisible(true);
    setEditing(true);
  };
 
  const addBooking = () => {
    const fullDate = new Date(selectedDay.fullDate);
 
    const startTimeNew = new Date(
      fullDate.getFullYear(),
      fullDate.getMonth(),
      fullDate.getDate(),
      selectedTimeSlot?.startTime?.getHours(),
      selectedTimeSlot?.startTime?.getMinutes(),
      selectedTimeSlot?.startTime?.getSeconds()
    );
 
    const endTimeNew = new Date(
      fullDate.getFullYear(),
      fullDate.getMonth(),
      fullDate.getDate(),
      selectedTimeSlot?.endTime?.getHours(),
      selectedTimeSlot?.endTime?.getMinutes(),
      selectedTimeSlot?.endTime?.getSeconds()
    );
 
    const dateString = `${moment(fullDate).format("dddd")} ${String(
      selectedDay?.day
    ).padStart(2, "0")} ${moment(fullDate).format("MMMM")}`;
    const timeSlotString = `${selectedTimeSlot.timeOfDay} (${selectedTimeSlot.duration})`;
 
    const bookingItem = {
      startTime: startTimeNew,
      endTime: endTimeNew,
      dateString: dateString,
      roomId: selectedRoom.roomId,
      roomName: selectedRoom.name,
      timeSlotString: timeSlotString,
      dayOfTheWeek: selectedDay?.dayOfTheWeek,
      day: selectedDay?.day,
      month: selectedDay?.month,
      index: idCounter,
    };
 
    const isDuplicate = bookingItems.some(
      (item) =>
        item.roomId === bookingItem.roomId &&
        item.dateString === bookingItem.dateString &&
        item.timeSlotString === bookingItem.timeSlotString
    );

    //const isOnlyRoomDiff = false;
    const isOnlyRoomDiff = bookingItems.some(
      (item) =>
          item.dateString === bookingItem.dateString &&
          item.timeSlotString === bookingItem.timeSlotString &&
          item.roomId !== bookingItem.roomId // Different room
    );

    //const isOnlyTimeslotDiff = false;
    const isOnlyTimeslotDiff = bookingItems.some(
      (item) =>
          item.dateString === bookingItem.dateString && // Same date
          item.roomId === bookingItem.roomId && // Same room
          item.timeSlotString !== bookingItem.timeSlotString
    );

    //const isSameDayDiffRoomAndTimeslot = false;
    const isSameDayDiffRoomAndTimeslot = bookingItems.some(
      (item) =>
          item.dateString === bookingItem.dateString && // Same day
          item.roomId !== bookingItem.roomId && // Different room
          item.timeSlotString !== bookingItem.timeSlotString // Different timeslot
    );
 
    //TODO: VALIDATE THIS RULE! IT MIGHT BE THE OTHER WAY AROUND
    const isBookingItemMorningOrAfternoon = bookingItems.some(
      (item) =>
        item.dateString === bookingItem.dateString &&
        bookingItem.timeSlotString.includes("Entire") &&
        (item.timeSlotString.includes("Morning") ||
          item.timeSlotString.includes("Afternoon"))
    );
 
    if (isBookingItemMorningOrAfternoon) setEntireDayCardDisabled(true);
    else setEntireDayCardDisabled(false);
 
    if (isDuplicate) {
      setIsDuplicateBookingItem(true);
    } else {
      setIsDuplicateBookingItem(false);
    }

    isOnlyRoomDiff ? setIsOnlyRoomDifferent(true) : setIsOnlyRoomDifferent(false);

    isOnlyTimeslotDiff ? setIsOnlyTimeslotDifferent(true) : setIsOnlyTimeslotDifferent(false);

    isSameDayDiffRoomAndTimeslot ? setIsSameDayDifferentRoomAndTimeslot(true) : setIsSameDayDifferentRoomAndTimeslot(false);
 
    if (maxBookingHoursReached) setMaxBookingHoursReached(false);

    const durationInMillis = endTimeNew - startTimeNew;

    const durationInHours = Math.floor(durationInMillis / 1000 / 60 / 60);

    const remainingTime = hoursRemaining - durationInHours;

    if(!isDuplicate && !isOnlyRoomDiff && 
      !isOnlyTimeslotDiff && !isSameDayDiffRoomAndTimeslot){
      setHoursRemaining(remainingTime);
    }


    if (!isDuplicate && !isBookingItemMorningOrAfternoon && !isOnlyRoomDiff 
      && !isOnlyTimeslotDiff && !isSameDayDiffRoomAndTimeslot) {
      setIdCounter(idCounter + 1);
 
      const updatedItems = [...bookingItems, bookingItem].sort(
        (a, b) => a.startTime - b.startTime
      );
 
      setBookingItems(updatedItems);
      setSelectedDay(null);
      setSelectedRoom(null);
      setSelectedTimeSlot(null);
      setIsBookingReady(false);
    }
  };
 
  const submitBooking = async () => {
    const maxBookingTime = bookingWindowDates[0]?.maxBookingTime;
 
    const totalDuration = bookingItems?.reduce((total, item) => {
      const startTime = new Date(item.startTime).getTime();
      const endTime = new Date(item.endTime).getTime();
      const duration = (endTime - startTime) / (1000 * 60 * 60); // convert ms to hours
      return total + duration;
    }, 0);

    if (totalDuration > maxBookingTime) setMaxBookingHoursReached(true);
    else {
      setMaxBookingHoursReached(false);
      setSaveLoading(true);
 
      const duration = `${moment(bookingWindow.startDate).format(
        "DD/MM/YYYY"
      )} - ${moment(bookingWindow.endDate).format("DD/MM/YYYY")}`;
 
      const booking = {
        bookingWindowId: bookingWindowId,
        bookingWindowName: bookingWindow?.name,
        bookingWindowLocation: bookingWindow?.location?.name,
        bookingWindowDuration: duration,
        customerGroupId: selectedCustomerGroup,
        attendeesCount: noOfAttendees,
        specialRequests: "",
        bookingItems: bookingItems,
      };
 
      try {
        const bookingCreated = await createBooking(booking, setErrorMessage, setDuplicateBookingItems);
        if (!bookingCreated) {
          setSaveLoading(false);
        } else {
          setSaveLoading(false);
 
          setSelectedLocation(null);
          setSelectedDay(null);
          setSelectedRoom(null);
          setBookingItems([]);
          setNoOfAttendees(0);
          setSelectedTimeSlot(null);
          setIdCounter(0);
          setEditing(false);
          setErrorMessage("");
 
          history.push({
            pathname: "/reservations/reservation-created-success",
            state: {
              from: "/reservations/create/:bookingWindowId",
              booking: booking,
              bookingId: bookingCreated?.bookingId,
              customerGroupSelected: customerGroup?.name,
              itoYearSelected: selectedITOYear,
            },
          });
        }
      } catch (err) {}
    }
  };

  const removeBookingItem = (bookingItem) => {
    const filteredBookings = bookingItems.filter(
      (item) => item.index !== bookingItem.index
    );
    setBookingItems(filteredBookings);

    const durationInMillis = bookingItem.endTime - bookingItem.startTime;
    const durationInHours = (durationInMillis / 1000 / 60 / 60);
  
    const remainingTime = hoursRemaining + durationInHours;
  
    setHoursRemaining(remainingTime);

    if (errorMessage) setErrorMessage("");
    if (maxBookingHoursReached) setMaxBookingHoursReached(false);
  };
 
  const isDayEnabled = (date) => {
    const roomAvailabilityForDate = availableRooms?.find(
      (item) => item.date === date.fullDate
    );
 
    if (
      roomAvailabilityForDate &&
      roomAvailabilityForDate.availabilityItems?.length > 0
    ) {
      return roomAvailabilityForDate?.availabilityItems?.every(
        (item) =>
          !item.isMorningAvailable &&
          !item.isAfternoonAvailable &&
          !item.isEntireDayAvailable
      );
    }
 
    return false;
  };
 
  const isRoomDisabled = (room) => {
    const roomAvailabilityForDate = availableRooms?.find((item) => {
      const availabilityDate = new Date(item.date);
      availabilityDate.setHours(0, 0, 0, 0);
      const dateToCompare = new Date(selectedDay?.fullDate);
      dateToCompare.setHours(0, 0, 0, 0);
      return availabilityDate.getTime() === dateToCompare.getTime();
    });
 
    const roomAvailability = roomAvailabilityForDate?.availabilityItems?.find(
      (roomItem) => roomItem.room.roomId === room.roomId
    );
 
    if (roomAvailability) {
      return (
        !roomAvailability.isMorningAvailable &&
        !roomAvailability.isAfternoonAvailable &&
        !roomAvailability.isEntireDayAvailable
      );
    }
 
    return false;
  };

  const isTimeslotDisabled = (time, selectedRoom) => {
    const roomAvailabilityForDate = availableRooms?.find((item) => {
      const availabilityDate = new Date(item.date);
      availabilityDate.setHours(0, 0, 0, 0);
      const dateToCompare = new Date(selectedDay?.fullDate);
      dateToCompare.setHours(0, 0, 0, 0);
      return availabilityDate.getTime() === dateToCompare.getTime();
    });
  
    const roomAvailability = roomAvailabilityForDate?.availabilityItems?.find(
      (roomItem) => roomItem?.room?.roomId === selectedRoom?.roomId
    );
  
    const isRoomDisabled = () => {
      if (roomAvailability) {
        return (
          !roomAvailability.isMorningAvailable &&
          !roomAvailability.isAfternoonAvailable
        ); // Entire Day depends on Morning and Afternoon
      }
      return false;
    };


    const isSameDayTimeslot = bookingItems.some(
      (item) =>
        { 
          const itemMonth = monthNames[item.startTime.getMonth()];
          const itemDay = item.startTime.getDate().toString();
          return selectedDay && itemMonth === selectedDay.month && itemDay === selectedDay.day;
        }
    );
  
    if (isRoomDisabled() || isSameDayTimeslot) {
      return true; // Disable all time slots if the room is disabled
    }

    //Time slot logic based on hoursRemaining
    if (hoursRemaining > 6) {
      // Disable Morning and Afternoon, enable Entire Day
      if (time?.timeOfDay !== "Entire Day") {
        return true; // Disable Morning and Afternoon
      }
      else{
        return roomAvailability && !roomAvailability.isEntireDayAvailable;
      }
    } else if (hoursRemaining === 6) {
      // Enable Morning and Afternoon based on room availability
      if (time?.timeOfDay === "Morning") {
        return roomAvailability ? !roomAvailability.isMorningAvailable : false;
      }
      if (time?.timeOfDay === "Afternoon") {
        return roomAvailability ? !roomAvailability.isAfternoonAvailable : false;
      }
      return true;
    }
    else{
      return true;
    }
  };
 
  const closeConfirmationModal = (confirmationChoice) => async () => {
    setConfirmationLoading(true);
    if (confirmationChoices.confirm === confirmationChoice) {
      history.push({
        pathname: "/reservations",
        state: {
          from: "/reservations/create/:bookingWindowId",
        },
      });
    }
    setDeleteConfirmationModalOpen(false);
    setConfirmationLoading(false);
  };
 
  const handleImageModalOpen = () => setIsImageModalOpen(true);
  const handleImageModalClose = () => setIsImageModalOpen(false);

  return (
    <>
      <LoaderComponent loading={loading}>
        <ConfirmationModal
          open={deleteConfirmationModalOpen}
          closeModal={closeConfirmationModal}
          confirmLoading={confirmationLoading}
          heading="Cancel Reservation"
          content={
            <div className="div-booking-item">
              <Unicons.UilExclamationCircle color={"#C62931"} size={25} /> You
              are about to cancel this reservation and lose all the changes. Please
              confirm that you would like to perform this action.
            </div>
          }
        />
        <Helmet>
          <title>De Beers Group Sight Room Reservation Portal - Reservations</title>
        </Helmet>
        <Grid>
          <Grid.Row className="customer-selection-header">
            <Grid.Column
              computer={6}
              mobile={16}
              className="customer-group-heading"
            >
              Sightholder: {customerGroup?.name}
            </Grid.Column>
            <Grid.Column
              computer={4}
              mobile={16}
              className="current-sight-column"
            ></Grid.Column>
            <Grid.Column computer={16} mobile={16}>
              <div className="border"></div>
            </Grid.Column>
          </Grid.Row>
          {bookingWindowDates && bookingWindowDates.length > 0 ? (
            <>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <StandardSmallHeading>
                    {bookingWindow?.name}
                  </StandardSmallHeading>
                  <StandardSubheading>Reserve your Sight Room</StandardSubheading>
                  <br></br>
                  <Form>
                    <Form.Group width="equal" fluid>
                      {locationOptions.length > 1 ? (
                        <Form.Dropdown
                          value={selectedLocation}
                          onChange={(_, { value }) => onLocationChange(value)}
                          label="Location"
                          placeholder="Select location"
                          search
                          selection
                          options={locationOptions}
                          width={8}
                          error={isRoomPanelVisible}
                        />
                      ) : (
                        <div className="div-col">
                          <Form.Field>
                            <label className="label-color">Location</label>
                          </Form.Field>
                          <Form.Field className="location-field">
                            {locationOptions[0]?.name}
                          </Form.Field>
                        </div>
                      )}
                    
                    <Form.Field className={`eight wide field ${isNumberOfAttendeesZero || isRoomCapacityReached ? 'error' : ''}`} >
                      <label>Total Attendees</label>
                      <NumericFormat
                        name="noOfAttendees"
                        placeholder="Enter total attendees"
                        getInputRef={attendeesRef}
                        width={8}
                        className="custom-attendee-input input-field-border"
                        isAllowed={(values) => {
                          const { floatValue } = values;
                          return floatValue >= 0 || !floatValue;
                        }}
                        onValueChange={(value) => onAttendeesChange(value)}
                        decimalScale={0}
                      />
                    </Form.Field>
                    </Form.Group>
                  </Form>
                  {isBookingSelectionVisible && (
                    <div>
                      <br />
                      <StandardSmallHeading>
                        Reservation Selection
                      </StandardSmallHeading>
                      <Segment className="segment-padding-left">
                        <p className="p-text-colour">Select a day</p>
                        <Grid>
                          <Grid.Row>
                            {bookingWindowDates?.map((date, index) => (
                              <Grid.Column computer={3} tablet={5} mobile={5}>
                                <BookingDayCard
                                  date={date}
                                  handleDayClick={handleDayClick}
                                  selectedDay={selectedDay}
                                  disabled={isDayEnabled(date)}
                                  tooltipMessage={"Day is fully booked."}
                                ></BookingDayCard>
                              </Grid.Column>
                            ))}
                          </Grid.Row>
                        </Grid>
                        <br />
                        {isRoomPanelVisible && (
                          <div>
                            <Grid>
                              <Grid.Row columns={2}>
                                <Grid.Column>
                                  <Label basic className="room-map-label">
                                    Select a Sight Room
                                  </Label>
                                  <Popup
                                      content="Max capacity is based on health & safety requirements."
                                      trigger={
                                        <span>
                                          <Unicons.UilInfoCircle className="info-icon" />
                                        </span>
                                      }
                                    />
                                </Grid.Column>
                                <Grid.Column textAlign="right">
                                  <Label
                                    basic
                                    onClick={handleImageModalOpen}
                                    className="view-map"
                                  >
                                    View Room map
                                  </Label>
                                  <Modal
                                    open={isImageModalOpen}
                                    onClose={handleImageModalClose}
                                    size="large"
                                    closeIcon
                                  >
                                    <Header>Rooms Map</Header>
                                    <Modal.Content>
                                      <iframe
                                        src={roomsMapUrl}
                                        width="100%"
                                        height="630px"
                                        title="Rooms Map"
                                      />
                                    </Modal.Content>
                                  </Modal>
                                </Grid.Column>
                              </Grid.Row>

                              <Grid.Row>
                                {locations[0] &&
                                  locations[0].rooms?.length > 0 &&
                                  locations[0].rooms?.filter((room) => !isRoomDisabled(room))
                                  .map((room, index) => (
                                    <Grid.Column
                                          className="column-room-padding"
                                          key={room.roomId || index}
                                      computer={8}
                                      tablet={8}
                                      mobile={16}
                                    >
                                      <Segment
                                        className={
                                          selectedRoom?.name === room.name
                                            ? "selected-room isSelected"
                                            : "selected-room isNotSelected"
                                        }
                                      >
                                        <Radio
                                          label={
                                            `${room.name} (Max ${room.capacity} attendee(s))`
                                          }
                                          value={room.name}
                                          checked={
                                            selectedRoom?.name === room.name
                                          }
                                          onChange={() => 
                                            onRoomChange(room.name)
                                          }
                                          className="room-name-width"
                                        />
                                      </Segment>
                                    </Grid.Column>
                                  ))}
                            </Grid.Row>
                            </Grid>
                            <br />
                          </div>
                        )}
 
                        {isTimeSlotPanelVisible && (
                          <div>
                            <p className="p-text-colour">Select a time slot</p>
                            <Grid>
                              <Grid.Row>
                                {timeslots &&
                                  timeslots?.length > 0 &&
                                  timeslots?.map((time, index) => (
                                     <Grid.Column key={index}
                                      computer={5}
                                      tablet={5}
                                      mobile={16}
                                    >
                                      <BookingTimeSlotCard
                                        handleTimeSlotCardClick={
                                          handleTimeSlotCardClick
                                        }
                                        selectedTimeSlot={selectedTimeSlot}
                                        timeslot={time}
                                          disabled={isTimeslotDisabled(
                                          time,
                                          selectedRoom
                                        )}
                                        tooltipMessage={
                                            isTimeslotDisabled(time, selectedRoom)
                                                ? "The time slot is not available"
                                                : undefined
                                        }
                                      ></BookingTimeSlotCard>
                                    </Grid.Column>
                                  ))}
                              </Grid.Row>
                            </Grid>
                            <br />
                          </div>
                        )}
                        {isSameDayDifferentRoomAndTimeslot && (
                          <Message error visible>
                          You cannot book the same day for a different room and a different timeslot.
                        </Message>
                        )}
                        {isOnlyTimeslotDifferent && (
                          <Message error visible>
                          You cannot book the same room on the same day with overlapping timeslots.
                        </Message>
                        )}
                        {isOnlyRoomDifferent && (
                          <Message error visible>
                          You cannot book a different room for the same date and timeslot.
                        </Message>
                        )}
                        {isDuplicateBookingItem && (
                          <Message error visible>
                            The same reservation was already added to the summary.
                          </Message>
                        )}
                        {isRoomCapacityReached && (
                          <Message error visible>
                            The total number of attendees are more than the
                            selected room capacity. The room capacity for{" "}
                            {selectedRoom?.name} is {selectedRoom?.capacity}.
                          </Message>
                        )}
                        {isNumberOfAttendeesZero && (
                          <Message error visible>
                            The total attendees must be a number greater than
                            zero.
                          </Message>
                        )}
                        {entireDayCardDisabled && (
                          <Message error visible>
                            The 'Entire Day' time slot cannot be selected
                            because a reservation on the same day has been added to
                            the summary for either Morning or Afternoon.
                          </Message>
                        )}
                        {selectedTimeSlot && (
                          <div className="right-text">
                            <Button
                              disabled={
                                !isBookingReady ||
                                isRoomCapacityReached ||
                                isNumberOfAttendeesZero ||
                                isDuplicateBookingItem ||
                                entireDayCardDisabled ||
                                isOnlyRoomDifferent ||
                                isOnlyTimeslotDifferent ||
                                isSameDayDifferentRoomAndTimeslot
                              }
                              primary
                              onClick={() => addBooking()}
                              className="btn-primary"
                            >
                              Add Reservation
                            </Button>
                          </div>
                        )}
                      </Segment>
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column computer={8} mobile={16} tablet={16}>
                  <br />
                  <StandardSmallHeading>Reservation Summary</StandardSmallHeading>
                  <br />
                  {bookingItems && bookingItems.length > 0 ? (
                    <Segment>
                      <BookingItems
                        bookingItems={bookingItems}
                        removeBookingItem={removeBookingItem}
                        isEditButtonVisible={false}
                        duplicateBookingItems={duplicateBookingItems}
                      ></BookingItems>
                    </Segment>
                  ) : (
                    <Segment className="segment-padding">
                      <p>Your reservations will be listed here</p>
                    </Segment>
                  )}
                  {maxBookingHoursReached && (
                    <Message error visible>
                      The reservation items added will exceed the maximum of number
                      of {bookingWindowDates[0]?.maxBookingTime} hours
                      allocated.{" "}
                    </Message>
                  )}
                  {errorMessage && (
                    <Message error visible>
                      There is already a reservation for the day(s), room(s) and
                      time slot(s).
                    </Message>
                  )}
                  <Button
                    disabled={
                      bookingItems?.length === 0 ||
                      maxBookingHoursReached ||
                      errorMessage
                    }
                    primary
                    floated="right"
                    onClick={submitBooking}
                    loading={saveLoading}
                    className="btn-primary"
                  >
                    Submit Reservation
                  </Button>
                  <Button
                    secondary
                    floated="right"
                    onClick={() => setDeleteConfirmationModalOpen(true)}
                    className="btn-secondary"
                  >
                    Cancel
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row></Grid.Row>
              <Grid.Row></Grid.Row>
              <Grid.Row></Grid.Row>
            </>
          ) : (
            <Message
              icon="warning circle"
              header={`Reservations Closed`}
              content={` Reservations are closed for the selected Sightholder.`}
            />
          )}
        </Grid>
      </LoaderComponent>
    </>
  );
};
 
export default RoomBookingsCreateContainer;
 