import React, { useState } from 'react';
import { Button, Divider } from "semantic-ui-react";
import { useParams } from 'react-router-dom';
import { StandardHeading } from '../../common/StandardHeading/StandardHeading';
import { Link } from "react-router-dom";
import {unsubscribe} from "../../lib/apiEmailUnsubscribe";

const EmailUnsubscribePage = () => {
  const { reference } = useParams();
  const [status, setStatus] = useState('pending');

  const handleUnsubscribe = async () => {
    try {
        const response = await unsubscribe(reference);

        if (response?.unsubscribeId) {
            setStatus('unsubscribed');
        } else {
            setStatus('error');
        }
    } catch (error) {
        console.error('Error unsubscribing:', error);
        setStatus('error');
    }
  };

  return (
    <div>
        {status === 'pending' && (
            <>
                <StandardHeading>
                    Unsubscribe from Emails
                </StandardHeading>
                <Divider className="divider-colour"/>
                <p className="extra-vertical-padding">
                    You are about to unsubscribe from our email services. If you proceed, you will no longer receive any system notifications originating from De Beers Group Sight Room Reservation Portal.
                </p>
                <Button secondary as={Link} to={`/`} className="btn-secondary">
                    Cancel
                </Button>
                <Button primary className="btn-primary" onClick={handleUnsubscribe}>
                Confirm Unsubscribe
                </Button>
            </>
        )}

        {status === 'unsubscribed' && (
            <>
                <StandardHeading>
                    Unsubscribed Successfully
                </StandardHeading>
                <Divider className="divider-colour"/>
                <p className="extra-vertical-padding">
                You have been unsubscribed from our email services. If this was a mistake, you can resubscribe on your
                <Link to="/profile" className="p-link-text-colour"> <u>profile page</u></Link>.
                </p>
            </>
        )}

        {status === 'error' && (
            <>
                <StandardHeading>
                    Error
                </StandardHeading>
                <Divider className="divider-colour"/>
                <p className="extra-vertical-padding">
                There was an error processing your request. Please try again later or contact our support team.
                </p>
            </>
        )}
    </div>
  );
};

export default EmailUnsubscribePage;