import { notifyError } from "./notifications";
import { createAxios } from "./axiosWrapper";
import { sortBy } from "lodash";
import { storageKeys } from "./storageKeys";
import { jwtDecode } from "jwt-decode";

const userApiUrl = `${process.env.REACT_APP_BOOKINGS_API_URL}/api`;

const axios = () => createAxios(userApiUrl);

export const getToken = () => {
  return localStorage.getItem(storageKeys.ACCESS_TOKEN);
};

export const getUserId = () => {
  const validToken = getToken();
  if (validToken) {
    const payload = jwtDecode(validToken);
    return payload.user_id;
  }
};

export const getUserCustomers = async () => {
  try {
  const userId = getUserId();
  if(userId){
     const requestUrl = "/CustomerGroups/get-customer-groups-for-user?userId=";
     const customerGroupRequest = await axios().get(`${requestUrl}${userId}`);
     const response = customerGroupRequest.data || [];
     return sortBy(response, ["customerGroupName"]);
  }
 } catch (error) {
   notifyError("Failed to get the customers.", error);
 }
};

export const getCustomerGroup = async (customerGroupId) => {
  try{
    const requestUrl = "/CustomerGroups/get-customer-group-by-id?customerGroupId=";
    const customerGroup = await axios().get(`${requestUrl}${customerGroupId}`);
    return customerGroup ? customerGroup.data : [];
  }catch (error) {
    notifyError("Failed to get the customer group name.", error);
  }
};
