import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import "semantic-ui-css/semantic.min.css";
import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {OneTrust} from "./modules/login/OneTrust"

const instrumentationKey = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY;

if (instrumentationKey) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <div>
      <OneTrust/>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </div>
  // </React.StrictMode>
);


// ReactDOM.render(
//   <div>
//     <OneTrust/>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </div>,
//   document.getElementById("root")
// );

serviceWorker.unregister();
