export const orchestrateLogoutAsync = async (
  oktaAuth,
  setLoggedOut,
  setLoginState
) => {
  await oktaAuth.signOut();
  setLoggedOut(true);
  setLoginState({
    isLoggedIn: false,
    isPending: false,
  });
};
