import "./Footer.scss";
import { Link } from "react-router-dom";

import { Container, Grid } from "semantic-ui-react";

import React from "react";

export const Footer = () => {
  const today = new Date();

  return (
    <Container className="site-footer">
      <Grid className="site-footer-content">
        <Grid.Row>
          <Grid.Column width={7} textAlign="left">
            <Link to="/termsconditions">Terms and Conditions</Link>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <Link to="/privacypolicy">Privacy Policy</Link>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <Link to="/cookies">Our Use of Cookies</Link>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={7} textAlign="left">
            <p>
              De Beers UK Limited (a member of the Anglo American plc group){" "}
              {today.getFullYear()}
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
