import "./MobileMenuContent.scss";

import { Link, withRouter } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";

import { Menu, Label } from "semantic-ui-react";
import classNames from "classnames";
import { tokenUserPermissions } from "../../lib/tokenUserPermissions";
import { validateRole } from "../../lib/validateRole";

export const MobileMenuContent = withRouter(({ location, setShowSidebar }) => {
  const [currentPath, setCurrentPath] = useState("");
  const extractCurrentLocation = (setCurrentPath, pathname) => {
    const path = pathname.split("/")[1] || "dashboard";
    setCurrentPath(path);
  };
// TODO: REMOVE THE FEATURE FLAG. THIS IS NOT NEEDED
  const gssRoomBookingsFeatureFlag = true;

  const gssRoomBookingsNewExpiryDate =
    process.env.REACT_APP_GSS_ROOM_BOOKINGS_NEW_EXPIRY_DATE;

  const expiryDate =
    gssRoomBookingsNewExpiryDate ?? new Date(gssRoomBookingsFeatureFlag);

  const currentDate = new Date();

  //TODO: Need to consider date formats
  const hasNewLabelExpired =
    gssRoomBookingsFeatureFlag && expiryDate && currentDate > expiryDate;

  useEffect(() => {
    extractCurrentLocation(setCurrentPath, location.pathname);
  }, [location.pathname]);
  const activeLeftNavCheck = (path) => ({
    "active-left-nav": path === currentPath,
  });

  return (
    <Fragment>
      {validateRole(tokenUserPermissions.CUSTOMER_PORTAL) &&
        gssRoomBookingsFeatureFlag && (
          <Menu.Item
            as={Link}
            to={"/reservations"}
            onClick={() => setShowSidebar(false)}
            className={classNames(
              "menu-item",
              "room-bookings",
              activeLeftNavCheck("reservations")
            )}
          >
            RESERVATIONS{" "}
            {!hasNewLabelExpired && <Label className="new-label">NEW</Label>}
          </Menu.Item>
        )}
    </Fragment>
  );
});
