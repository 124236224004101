import "./BookingDayCard.scss";
import React from "react";
import { Card, Popup } from "semantic-ui-react";

export const BookingDayCard = ({
  date,
  handleDayClick,
  selectedDay,
  disabled,
  tooltipMessage,
}) => {
  return (
    <Popup
      content={tooltipMessage}
      trigger={
        <div>
          <Card
            className={
              disabled
                ? "booking-card selected-day isNotSelected disabled"
                : date === selectedDay
                ? "booking-card selected-day isSelected"
                : "booking-card selected-day isNotSelected"
            }
            onClick={() => {
              handleDayClick(date, disabled);
            }}
          >
            <div>
              <p className="p-booking-item-week-day">{date.dayOfTheWeek}</p>
              <h2 className="h2-booking-item-day">{date.day}</h2>
              <p className="p-booking-item-week-day">{date.month}</p>
            </div>
          </Card>
        </div>
      }
      disabled={!disabled || !tooltipMessage}
    />
  );
};

export default BookingDayCard;
