import "./BookingTimeSlotCard.scss";
import React from "react";
import { Card, Popup } from "semantic-ui-react";

export const BookingTimeSlotCard = ({
  timeslot,
  handleTimeSlotCardClick,
  selectedTimeSlot,
  disabled,
  tooltipMessage,
}) => {
  return (
    <Popup
      content={tooltipMessage}
      trigger={
        <div>
          <Card
            className={
              disabled
                ? "booking-time-slot-card selected-timeSlot isNotSelected disabled"
                : timeslot?.timeOfDay === selectedTimeSlot?.timeOfDay
                ? "booking-time-slot-card selected-timeSlot isSelected"
                : "booking-time-slot-card selected-timeSlot isNotSelected"
            }
            onClick={() => {
              handleTimeSlotCardClick(timeslot, disabled);
            }}
            disabled={true}
          >
            <div>
              <p className="p-booking-item-week-day">{timeslot.timeOfDay}</p>
              <p className="p-booking-item-week-day">{timeslot.duration}</p>
            </div>
          </Card>
        </div>
      }
      disabled={!disabled || !tooltipMessage}
    />
  );
};

export default BookingTimeSlotCard;
