import "./FutureBookings.scss";
import React, { useState, useEffect } from "react";
import { Grid, Divider, Button, Segment } from "semantic-ui-react";
import { LoaderComponent } from "../../common/LoaderComponent";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import { getPastBookingDataAsync } from "../../lib/apiBookings";
import { Link } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";

/*
TODO: Move in-line styling
*/

const getBookingData = async (
  selectedCustomerGroup,
  selectedItoYear,
  setLoadingBookingData,
  setBookingData,
  isPast
) => {
  setLoadingBookingData(true);
  const data = await getPastBookingDataAsync(
    selectedCustomerGroup,
    selectedItoYear,
    isPast
  );

  await setBookingData(data);

  setLoadingBookingData(false);
};

export const PastBookings = ({
  selectedItoYear,
  selectedCustomerGroup,
  isPast,
}) => {
  const [loadingBookingData, setLoadingBookingData] = useState(false);
  const [bookingData, setBookingData] = useState([]);

  useEffect(() => {
    if (selectedItoYear && selectedCustomerGroup) {
      getBookingData(
        selectedCustomerGroup,
        selectedItoYear,
        setLoadingBookingData,
        setBookingData,
        isPast
      );
    }
  }, [selectedItoYear, selectedCustomerGroup]);

  return (
    <LoaderComponent loading={loadingBookingData}>
      {isPast ? (
        <StandardSubheading>Past Reservations</StandardSubheading>
      ) : (
        <StandardSubheading>Current Reservations</StandardSubheading>
      )}
      <Divider className="divider-colour" />
      {bookingData?.length > 0 ? (
        bookingData?.map((booking, index) => (
          <Segment key={index} className="booking-card">
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <h1 className="booking-h1-heading">
                    {booking.bookingWindowName}
                  </h1>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {isPast ? (
                    <Button
                      className="btn-secondary"
                      floated="right"
                      as={Link}
                      to={{
                        pathname: `/reservations/manage-reservation/${booking?.bookingId}`,
                        state: { isReadOnly: true },
                      }}
                    >
                      View
                    </Button>
                  ) : (
                    <Button
                      className="btn-secondary"
                      floated="right"
                      Icon={Unicons.UilEditAlt}
                      as={Link}
                      to={{
                        pathname: `/reservations/manage-reservation/${booking?.bookingId}`,
                        state: { isReadOnly: false },
                      }}
                    >
                      Manage
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={3}>
                  <p className="standard-booking-item-heading">Date</p>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={5}>
                  <p className="standard-booking-item-heading">Time</p>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={3}>
                  <p className="standard-booking-item-heading">Room</p>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={3}>
                  <p className="standard-booking-item-heading">Location</p>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={2}>
                  <p className="standard-booking-item-heading">Attendees</p>
                </Grid.Column>
              </Grid.Row>

              {booking.bookingItemsForDisplay.map((item, itemIndex) => (
                <Grid.Row key={itemIndex} className="booking-data-row">

                  <Grid.Column mobile={8} tablet={8} computer={3}>
                    <p className="standard-booking-item-details">
                      {`${item.dayOfTheWeek}, ${item.day} ${item.month}`}
                    </p>
                  </Grid.Column>

                  <Grid.Column mobile={8} tablet={8} computer={5}>
                    <p className="standard-booking-item-details">
                      {item.timeSlot}
                    </p>
                  </Grid.Column>

                  <Grid.Column mobile={8} tablet={8} computer={3}>
                    <p className="standard-booking-item-details">
                      {item.roomName}
                    </p>
                  </Grid.Column>

                  <Grid.Column mobile={8} tablet={8} computer={3}>
                    <p className="standard-booking-item-details">
                      {booking.bookingWindowLocation}
                    </p>
                  </Grid.Column>

                  <Grid.Column mobile={8} tablet={8} computer={2}>
                    <p className="standard-booking-item-details">
                      {booking.noOfAttendees}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
          </Segment>
        ))
      ) : (
        <Segment>
          <p>There are no reservations.</p>
        </Segment>
      )}
    </LoaderComponent>
  );
};

export default PastBookings;
