import { notifyError } from "./notifications";

import { createAxios } from "./axiosWrapper";

const orderManagementApiUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api`;

const axios = () => createAxios(orderManagementApiUrl);

export const cleanWhiteSpace = (inputText) => {
  return inputText.replace(/ +$/, "");
};

export const getCustomer = async (customerId) => {
  try {
    //TODO: Undo this change!!
    var customerData = {
      "customerId": 275,
      "customerName": "A.Dalumi Botswana",
      "salesLocation": {
        "salesLocationId": 3,
        "salesLocationName": "De Beers Botswana",
        "salesLocationReference": "6d6a6220-5cf7-44e7-922a-d363ab6c1513",
        "weighting": 90.00,
        "salesLocationCode": 6
      },
      "salesLocationId": 3,
      "createdByUserId": null,
      "axReferenceCode": "10025",
      "customerGroupId": 107,
      "isActive": true,
      "brokerageId": null,
      "representativeCustomers": [
        {
          "representativeCustomerId": 7,
          "representativeId": 23,
          "customerId": 275,
          "representative": {
            "representativeId": 23,
            "representativeTypeId": 4,
            "title": null,
            "firstName": "Jane",
            "lastName": "Doe",
            "axRepresentativeCode": null,
            "isActive": true,
            "dateCreated": "2024-11-04T06:54:25.4163266",
            "representativeRef": "e78b6b4f-2fba-48ba-8620-57899ac99b4b",
            "representativeTypeDescription": "Customer"
          }
        }
      ]
    }
    return customerData;
    // const customerResults = await axios().get(`/Customers/${customerId}`);
    // const customer = customerResults.data;

    // return customer;
  } catch (err) {
    notifyError("Failed to find a customer.", err);
  }
};

export const getCustomerPortalMaintenanceStatus = async () => {
  try {
    //TODO: UNDO THIS CHANGE!!
    //return false;
    const url = "/CustomerPortalMaintenanceConfiguration";
    const response = await axios().get(url);
    return response.data;
  } catch (err) {
    return true;
  }
};

export const getCustomerGroupResults = async ({
  location,
  kam,
  customerGroupName,
}) => {
  try {
    let requestUrl = `/CustomerGroups?`;

    if (kam) {
      requestUrl += `&kam=${kam}`;
    }
    if (location) {
      requestUrl += `&location=${location}`;
    }
    if (customerGroupName) {
      requestUrl += `&customerGroupName=${customerGroupName}`;
    }

    const customerGroupDetails = await axios().get(`${requestUrl}`);
    return customerGroupDetails.data;
  } catch (error) {
    notifyError("Failed to load customer groups.", error);
  }
};

export const getCustomerGroupOrderManagementPermission = async (
  customerGroupId
) => {
  if (customerGroupId) {
    try {
      const request = `/CustomerPortalOrder/check-user-customer-group-order-management-permission?customerGroupId=${customerGroupId}`;
      const customerPermission = await axios().get(request);
      return customerPermission.data;
    } catch (err) {
      notifyError("Could not fetch customer permissions.", err);
    }
  }
};
