import { notifyError } from "./notifications";
const { createAxios } = require("./axiosWrapper");

const roomsMapApiUrl = `${process.env.REACT_APP_BOOKINGS_API_URL}/api/Rooms`;
const axios = () => createAxios(roomsMapApiUrl);

export const getRoomsMapUrl = async (setRoomsMapUrl) => {
    try {
      const requestUrl = `map`;  
      const response = await axios().get(requestUrl, {responseType: 'blob'});

      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], {type: 'application/pdf'});
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      setRoomsMapUrl(fileURL);
    } catch (err) {
      notifyError("Failed to load the rooms map.", err);
    }
};