import "./RoomBookingsCommonStyles.scss";
import React, { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Grid, Segment, Button, Icon } from "semantic-ui-react";
import { CustomerGroupSelector } from "../../common/CustomerGroupSelector/CustomerGroupSelector";
import { CustomerSelectionSlider } from "../../common-mobile/CustomerSelectionSlider/CustomerSelectionSlider";
import UpcomingBookings from "./UpcomingBookings";
import FutureBookings from "./FutureBookings";
import { SelectedCustomerGroupContext } from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { Link } from "react-router-dom";

export const RoomBookingsContainer = () => {
  const [selectedITOYear, setSelectedITOYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [hasUpcomingBookings, setHasUpcomingBookings] = useState([]);

  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);

  useEffect(() => {
    if (yearOptions) {
      const currentYear = new Date().getFullYear();
      setSelectedITOYear(currentYear.toString());
    }
  }, [yearOptions]);

  const handleUpcomingBookingsStatus = (hasBooking) => {
    setHasUpcomingBookings(hasBooking);
  };

  return (
    <>
      <Helmet>
        <title>De Beers Group Sight Room Reservation Portal - Reservations</title>
      </Helmet>
      <Grid className="scrollable-div-content">
        <Grid.Row className="customer-selection-header desktop">
          <Grid.Column computer={4} mobile={16}>
            <CustomerGroupSelector hideCustomerSelector={true} />
          </Grid.Column>
          <Grid.Column
            computer={4}
            mobile={16}
            className="current-sight-column"
          ></Grid.Column>
          <Grid.Column computer={16}>
            <div className="border"></div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="mobile-view">
          <Grid.Column width={16}>
            <CustomerSelectionSlider
              setSelectedYear={setSelectedITOYear}
              selectedYear={selectedITOYear}
              yearOptions={yearOptions}
              displayCustomer={false}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={10} computer={8}>
            <Segment>
              <UpcomingBookings
                selectedCustomerGroup={selectedCustomerGroup}
                selectedItoYear={selectedITOYear}
                onUpdateHasBookings={handleUpcomingBookingsStatus}
              ></UpcomingBookings>
              <br></br>
              <Button
                fluid
                secondary
                as={Link}
                to={`/reservations/past`}
                className="btn-secondary"
              >
                View all past reservations
              </Button>
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <Segment>
              <FutureBookings
                selectedItoYear={selectedITOYear}
                selectedCustomerGroup={selectedCustomerGroup}
                upcomingBookingsData={hasUpcomingBookings}
              ></FutureBookings>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default RoomBookingsContainer;
