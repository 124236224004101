// import jwt from 'jsonwebtoken';
import { jwtDecode } from "jwt-decode";
import { getToken } from './authentication';
const scopesKey = process.env.REACT_APP_JWT_SCOPES_KEY;

export const validateRole = (permission) => {
    if (permission === undefined) {
        throw new Error('Permission was undefined');
    }

    const validAccessToken = getToken();
    if (!validAccessToken) { return false };
    const payload = jwtDecode(validAccessToken);
    const scopes = payload[scopesKey];
    if (!scopes) { return false; }
    return scopes.includes(permission);
};

export const isBrokerOrCustomer = (tokenUserPermissions) => {
    const validAccessToken = getToken();

    if (!validAccessToken)
    {
        return false
    };

    const payload = jwtDecode(validAccessToken);
    const scopes = payload[scopesKey];

    if (!scopes)
    {
        return false;
    }

    const isBroker = scopes.includes(tokenUserPermissions.REPRESENTATIVE_TYPES.BROKER);
    const isCustomer = scopes.includes(tokenUserPermissions.REPRESENTATIVE_TYPES.CLIENT);

    if (isBroker || isCustomer)
    {
        return true;
    }
    else
    {
        return false;
    }
};