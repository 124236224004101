import "./FutureBookings.scss";
import React, { useState, useEffect } from "react";
import { Grid, Divider, Card, Button, Segment, Popup } from "semantic-ui-react";
import { LoaderComponent } from "../../common/LoaderComponent";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";
import { getBookingWindowForCustomerGroupAsync } from "../../lib/apiBookingWindows";
import moment from "moment";
import { Link } from "react-router-dom";

const getBookingWindowData = async (
  selectedItoYear,
  customerGroupId,
  setLoadingBookingData,
  setBookingData
) => {
  setLoadingBookingData(true);

  const data = await getBookingWindowForCustomerGroupAsync(
    customerGroupId
  );

  await setBookingData(data);

  setLoadingBookingData(false);
};

export const FutureBookings = ({ selectedItoYear, selectedCustomerGroup, upcomingBookingsData }) => {
  const [loadingBookingWindowData, setLoadingBookingWindowData] =
    useState(false);
  const [bookingWindowData, setBookingWindowData] = useState([]);
  const [hasSelectedCustomerGroup, setHasSelectedCustomerGroup] = useState(false);

  useEffect(() => {
    setHasSelectedCustomerGroup(selectedCustomerGroup == null);
    if (selectedItoYear && selectedCustomerGroup) {
      getBookingWindowData(
        selectedItoYear,
        selectedCustomerGroup,
        setLoadingBookingWindowData,
        setBookingWindowData
      );
    }
  }, [selectedItoYear, selectedCustomerGroup]);

  const currentDate = new Date();

  //check if any booking data present in Upcoming bookings for Sight Week
  const isWindowBooked = (windowId) =>
    upcomingBookingsData?.some((booking) => booking.bookingWindowId === windowId);

  return (
    <LoaderComponent loading={loadingBookingWindowData}>
      <StandardSubheading>Future Sight Weeks</StandardSubheading>

      <Divider className="divider-colour" />
      {bookingWindowData?.length > 0 ? (
        bookingWindowData?.map((window, index) => (
          <Card className="notification-card" key={window.bookingWindowId}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <StandardSmallHeading>{window.name}</StandardSmallHeading>
                  <p className="p-sight-week-date">
                    {moment(window.startDate).format("DD/MM/YYYY")} -{" "}
                    {moment(window.endDate).format("DD/MM/YYYY")}
                  </p>
                </Grid.Column>
                <Grid.Column width={8} className="button-sight-week-card">
                  {window.isWindowOpen ? (
                    <Popup
                      trigger={
                        <div style={{ display: "inline-block" }}>
                          <Button
                            primary
                            floated="right"
                            as={Link}
                            to={`/reservations/create/${window.bookingWindowId}`}
                            className="btn-primary"
                            disabled={ isWindowBooked(window.bookingWindowId) || hasSelectedCustomerGroup}
                          >
                            Reserve Now
                          </Button>
                        </div>
                      }
                      content={
                        isWindowBooked(window.bookingWindowId)
                              ? "You already have an existing reservation. Please manage it instead."
                          : hasSelectedCustomerGroup
                          ? "No Sightholder selected."
                          : null
                      }
                      position="top center"
                      disabled={!isWindowBooked(window.bookingWindowId) && !hasSelectedCustomerGroup}
                    />
                  ) : (
                    moment(window.endDate) > currentDate && <p>Not Yet Open</p>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card>
        ))
      ) : (
        <Segment>
          <p>There are no sight weeks configured for the year.</p>
        </Segment>
      )}
    </LoaderComponent>
  );
};

export default FutureBookings;
