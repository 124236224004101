import "./CustomerSelectionSlider.scss";
import * as Unicons from "@iconscout/react-unicons";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";
import {
  SelectedCustomerContext,
  SelectedCustomerGroupContext,
} from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { CustomerGroupSelector } from "../../common/CustomerGroupSelector/CustomerGroupSelector";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import { fetchCustomerGroup } from "../../modules/profile/BankList.functions";
import { getCustomer } from "../../lib/apiOrderManagement";

export const CustomerSelectionSlider = ({
  currentSight,
  loadingSalesPeriods,
  loadingCurrentSalesPeriod,
  setSelectedYear,
  selectedYear,
  yearOptions,
  setSelectedSalesPeriod,
  selectedSalesPeriod,
  salesPeriodOptions,
  itoYears,
  selectedITOYear,
  itoYearLoading,
  setSelectedITOYear,
  displayITOYear,
  displayCustomer,
}) => {
  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);
  const { selectedCustomer } = useContext(SelectedCustomerContext);
  const [expanded, setExpanded] = useState(false);
  const [customerGroup, setCustomerGroup] = useState();
  const [customer, setCustomer] = useState();

  useEffect(() => {
    const getCustomerData = async () => {
      const customerTemp = await getCustomer(selectedCustomer);

      setCustomer(customerTemp);
    };
    fetchCustomerGroup({
      setCustomerGroup,
      customerGroupId: selectedCustomerGroup,
    });

    if (selectedCustomer) {
      getCustomerData();
    }
  }, [selectedCustomerGroup, selectedCustomer]);

  return (
    <div className="customer-selection-container">
      <Segment raised className="customer-selection-slider-segment">
        {!expanded && (
          <div className="minimized-customer-selector">
            <StandardSubheading>Sightholder Details</StandardSubheading>
            <StandardSmallHeading>{customerGroup?.name}</StandardSmallHeading>
            <StandardSmallHeading>
              {salesPeriodOptions?.length > 0 && <>Selected sight:</>}
              {!(salesPeriodOptions?.length > 0) && yearOptions?.length > 0 && (
                <>Selected year:</>
              )}{" "}
              {salesPeriodOptions?.length > 0 &&
                salesPeriodOptions?.find((x) => x.key === selectedSalesPeriod)
                  ?.name}
              {!(salesPeriodOptions?.length > 0) &&
                yearOptions?.length > 0 &&
                yearOptions?.find((x) => x.key === selectedYear)?.name}
            </StandardSmallHeading>
          </div>
        )}
        {expanded && (
          <Grid columns={1}>
            <Grid.Column>
              <StandardSubheading>Sightholder Details</StandardSubheading>
            </Grid.Column>
            <Grid.Column>
              <CustomerGroupSelector hideCustomerSelector={!displayCustomer} />
              <Form loading={loadingSalesPeriods || loadingCurrentSalesPeriod}>
                <Form.Group>
                  {yearOptions?.length > 0 && (
                    <Form.Dropdown
                      width={4}
                      id="year"
                      placeholder="Year"
                      onChange={async (_, { value }) => setSelectedYear(value)}
                      search
                      selection
                      className="year-selector"
                      value={selectedYear}
                      options={yearOptions}
                      label="ITO Year"
                    />
                  )}
                  {salesPeriodOptions?.length > 0 && (
                    <Form.Dropdown
                      width={4}
                      id="salesPeriod"
                      placeholder="Period"
                      onChange={async (_, { value }) =>
                        setSelectedSalesPeriod(value)
                      }
                      className="sales-period-selector"
                      search
                      selection
                      value={selectedSalesPeriod}
                      options={salesPeriodOptions}
                      label="Sight"
                    />
                  )}
                  {displayITOYear && (
                    <Form.Dropdown
                      placeholder="ITO Year"
                      label="ITO Year"
                      options={itoYears}
                      search
                      selection
                      width={4}
                      value={selectedITOYear}
                      loading={itoYearLoading}
                      onChange={(e, { value }) => {
                        setSelectedITOYear(value);
                      }}
                    ></Form.Dropdown>
                  )}
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid>
        )}
        <Button
          className="circular-button"
          onClick={() => setExpanded(!expanded)}
        >
          {!expanded && <Unicons.UilAngleDown size={25} />}
          {expanded && <Unicons.UilAngleUp size={25} />}
        </Button>
      </Segment>
    </div>
  );
};

export default CustomerSelectionSlider;
